<template>
      <v-container fluid fill-height>
        <v-layout align-center justify-center>
          <v-flex xs12 sm8 md4>
            <v-card class="elevation-12">
              <v-toolbar dark color="primary">
                <v-toolbar-title>Login</v-toolbar-title>
              </v-toolbar>
              <v-card-text>
                <v-form>
                  <v-text-field prepend-icon="mdi-account" name="login" label="Usuario" type="text" v-model="input.user"
                                required></v-text-field>
                  <v-text-field @keypress.enter="login()" id="password" prepend-icon="mdi-lock" name="password"
                                label="Password" type="password" v-model="input.pass" required></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="login()">Login</v-btn>
              </v-card-actions>
            </v-card>
          </v-flex>
        </v-layout>
      </v-container>

</template>

<script>

export default {
  data: () => ({
    drawer: null,
    input: {
      user: "",
      pass: ""
    }
  }),
  props: {
    source: String
  },
  methods: {
    async login() {
      const response = await this.$store.dispatch('app/login', this.input);

      if (response.status === 200) {
        await this.$router.push('/home');
      }
    }

  }
}
</script>
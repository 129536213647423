<template>
  <v-container fluid>
    <v-form ref="orderForm">
      <v-col md="6">
        <v-autocomplete
          label="Sector"
          v-model="editedItem.sector"
          :items="getSectors"
          item-text="description"
          item-value="id"
          :rules="required"
          @change="getSectorData"
        />
      </v-col>

      <v-radio-group
        row mandatory
        :readonly="editedIndex > -1"
        label="Tipo"
        v-model="editedItem.type"
        :rules="required"
      >
        <v-radio class="ml-5" :value="CORRECTIVE_ORDER()" label="Correctiva" />
        <v-radio :value="PREVENTIVE_ORDER()" label="Preventiva" />
      </v-radio-group>

      <v-col md="3">
        <FormDate :label="dateLabel" :date="editedItem.scheduleDate" :isRequired="isRequired"
                  @on-change="onChangeDate"/>
      </v-col>

      <v-col md="6">
        <v-autocomplete
          v-if="!isCorrective"
          v-model="editedItem.planning"
          :items="editedItem.sectorObject.plannings"
          item-text="concept.title"
          item-value="id"
          :rules="required"
          label="Concepto"
        />
      </v-col>

      <v-textarea label="Observaciones" v-model="editedItem.observations" v-if="isCorrective"></v-textarea>

      <v-btn @click="save">Guardar</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import BaseForm from "@/components/base/BaseForm.vue";
import {mapGetters} from "vuex";
import DialogDelete from "@/components/base/DialogDelete.vue";
import FormDate from "@/components/base/FormDate.vue";
import httpStatus from "http-status";
import {BUILDING_CORRECTIVE_ORDER, BUILDING_PREVENTIVE_ORDER} from "@/types/BuildingOrderTypes";

export default {
  name: "BuildingOrderForm",
  components: {BaseForm, DialogDelete, FormDate},
  data() {
    return {
      dateLabel: 'Fecha de programación',
      required: [
        v => !!v || 'Este campo es obligatorio',
      ],
      isRequired: true,
    }
  },

  props: {
    editedItem: {
      type: Object,
      required: true
    },
    editedIndex: {
      type: Number,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      getSectors: 'sectors/getSectors',
      getUsername: 'app/getUserName'
    }),
    isCorrective() {
      return this.editedItem.type === BUILDING_CORRECTIVE_ORDER
    }
  },

  async created() {
  },

  methods: {
    PREVENTIVE_ORDER() {
      return BUILDING_PREVENTIVE_ORDER
    },
    CORRECTIVE_ORDER() {
      return BUILDING_CORRECTIVE_ORDER
    },

    async getSectorData(id) {
      const sectorObject = await this.$store.dispatch('sectors/getSector', id)
      this.editedItem.sectorObject.plannings = sectorObject.plannings
      this.editedItem.planning = ''
      this.$forceUpdate()
    },

    async save() {
      if (!this.$refs.orderForm.validate()) {
        return;
      }

      this.editedItem.issuer = this.getUsername
      const response = await this.$store.dispatch('buildingOrders/saveBuildingOrder', {
        editedIndex: this.editedIndex,
        item: this.editedItem
      });

      if (response.status === httpStatus.CREATED) {
        this.$emit('on-save')
      }
    },

    onChangeDate(date) {
      this.editedItem.scheduleDate = date
    }
  }
}
</script>

<style scoped>

</style>
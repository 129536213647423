<template>
  <v-container fluid>
    <v-form ref="orderForm">
      <v-col md="6">
        <v-autocomplete
          label="Máquina"
          v-model="editedItem.machine"
          :items="getMachinesSelect"
          item-text="description"
          item-value="id"
          :rules="required"
          @change="getMachineData"
        >
          <template v-slot:selection="data">
            {{ data.item.description + ' (Cod. ' + data.item.code.value + ')' }}
          </template>

          <template v-slot:item="data">
            <v-list-item-content v-text="data.item.description + ' (Cod. ' + data.item.code.value + ')'"></v-list-item-content>
          </template>

        </v-autocomplete>
      </v-col>

      <v-radio-group
        row mandatory
        :readonly="editedIndex > -1"
        label="Tipo"
        v-model="editedItem.type"
        :rules="required"
      >
        <v-radio class="ml-5" :value="CORRECTIVE_ORDER()" label="Correctiva" />
        <v-radio :value="PREVENTIVE_ORDER()" label="Preventiva" />
      </v-radio-group>

      <v-col md="3">
        <FormDate :label="dateLabel" :date="editedItem.scheduleDate" :isRequired="isRequired"
          @on-change="onChangeDate"/>
      </v-col>

      <v-col md="6">
        <v-autocomplete
          v-if="!isCorrective"
          v-model="editedItem.planning"
          :items="editedItem.machineObject.plannings"
          item-text="concept.title"
          item-value="id"
          :rules="required"
          label="Concepto"
        />
      </v-col>

      <v-textarea label="Observaciones" v-model="editedItem.observations" v-if="isCorrective"></v-textarea>

      <v-btn @click="save">Guardar</v-btn>
    </v-form>
  </v-container>
</template>

<script>
import BaseForm from "@/components/base/BaseForm.vue";
import {mapGetters} from "vuex";
import DialogDelete from "@/components/base/DialogDelete.vue";
import FormDate from "@/components/base/FormDate.vue";
import httpStatus from "http-status";
import {CORRECTIVE_ORDER, PREVENTIVE_ORDER} from "@/types/OrderTypes";

export default {
  name: "OrderForm",
  components: {BaseForm, DialogDelete, FormDate},
  data() {
    return {
      dateLabel: 'Fecha de programación',
      machine: {},
      required: [
        v => !!v || 'Este campo es obligatorio',
      ],
      isRequired: true,
    }
  },

  props: {
    editedItem: {
      type: Object,
      required: true
    },
    editedIndex: {
      type: Number,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      getMachines: 'machines/getMachines',
      getUsername: 'app/getUserName'
    }),
    getMachinesSelect() {
      return this.isCorrective
        ? this.getMachines
        : this.getScheduledMachines
    },
    getScheduledMachines() {
      return this.getMachines.filter(machine => machine.plannings.length > 0)
    },
    isCorrective() {
      return this.editedItem.type === CORRECTIVE_ORDER
    },
  },

  async created() {
    await this.$store.dispatch('machines/getMachines')
  },

  methods: {
    PREVENTIVE_ORDER() {
      return PREVENTIVE_ORDER
    },
    CORRECTIVE_ORDER() {
      return CORRECTIVE_ORDER
    },

    async getMachineData(id) {
      const machineObject = await this.$store.dispatch('machines/getMachine', id)
      this.editedItem.machineObject.plannings = machineObject.plannings.filter(planning => !planning.isAutonomous)
      this.editedItem.planning = ''
      this.$forceUpdate()
    },

    async save() {

      if (!this.$refs.orderForm.validate()) {
        return;
      }

      this.editedItem.issuer = this.getUsername
      const response = await this.$store.dispatch('orders/saveOrder', {
        editedIndex: this.editedIndex,
        item: this.editedItem
      });

      if (response.status === httpStatus.CREATED) {
        this.$emit('on-save')
      }
    },

    onChangeDate(date) {
      this.editedItem.scheduleDate = date
    }

  }
}
</script>

<style scoped>

</style>
import axios, {AxiosResponse} from "axios";
import {BASE_URL} from "@/services/config";
import {MachineRequest} from "@/services/Request/MachineRequest";
import {PartRequest} from "@/services/Request/PartRequest";
import {OrderRequest} from "@/services/Request/OrderRequest";
import {CloseOrderRequest} from "@/services/Request/CloseOrderRequest";
import {ConceptRequest} from "@/services/Request/ConceptRequest";
import {PlanningRequest} from "@/services/Request/PlanningRequest";

export default {

    //MACHINES
    async getMachines(): Promise<any> {
        const response = await axios.get(BASE_URL() + '/machines');
        return response.data;
    },

    async getMachine(machineId: string): Promise<void> {
        const response = await axios.get(BASE_URL() + '/machines', {
            params: {
                machineId
            }
        });
        return response.data;
    },

    async getMachinesReport() {
        return await axios.get(BASE_URL() + "/machines_report", {responseType: 'blob'})
    },

    async createNewMachine(data: MachineRequest): Promise<AxiosResponse> {
        return await axios.put(BASE_URL() + '/machines', data);
    },

    async deleteMachine(id: string): Promise<AxiosResponse> {
        return await axios.delete(BASE_URL() + '/machines', {data: {id: id}});
    },

    async uploadMachineFile(data: MachineRequest): Promise<AxiosResponse> {
        let formData = new FormData();
        formData.append('id', data.id);
        formData.append('fileId', data.file.id);
        formData.append('file', data.transferredFile, data.transferredFile.name);
        return await axios.put(BASE_URL() + '/machines_file', formData, {
            headers: {
                'Content-Type': 'multipart/form-data; boundary=file'
            }
        });
    },

    async getMachineFile(id: string): Promise<AxiosResponse> {
        return await axios.get(BASE_URL() + '/machine_file', {responseType: 'blob', params: {id: id}});
    },

    async deleteMachineFile(fileId: string): Promise<AxiosResponse> {
        return await axios.delete(BASE_URL() + '/machine_file', {data: {id: fileId}});
    },

    //PARTS
    async getParts(): Promise<AxiosResponse> {
        const response = await axios.get(BASE_URL() + '/parts');
        return response.data;
    },

    async createPart(part: PartRequest): Promise<AxiosResponse> {
        return await axios.put(BASE_URL() + "/parts", part);
    },

    async addMachineToPart(data: {partId: string, machineId: string}): Promise<AxiosResponse> {
        return await axios.put(BASE_URL() + "/part_machine", data);
    },

    async deletePart(item: PartRequest): Promise<AxiosResponse> {
        return await axios.delete(BASE_URL() + "/parts", {data: {id: item.id}});
    },

    async removeMachine(data: {part: PartRequest, machine: MachineRequest}): Promise<AxiosResponse> {
        return await axios.delete(BASE_URL() + "/part_machine_remove", {data: {partId: data.part.id, machineId: data.machine.id}});
    },

    //ORDERS

    async saveSchedule( data: {plannings: Array<PlanningRequest>, from: Date, to: Date}) {
        const planningIds = data.plannings.map((planning) => {
            return planning.id;
        });

        return await axios.put(BASE_URL() + "/schedule-orders",
          {planningIds: planningIds, from: data.from, to: data.to}
        )
    },

    async getOrders(period: {from: string, to: string}) {
        return await axios.get(BASE_URL() + "/orders_by_period", {
            params: {
                from: period.from,
                to: period.to
            }
        });
    },

    async getOrdersByMachine(searchData: { machineId: string, from?: string, to?: string }) {
        return await axios.get(BASE_URL() + "/orders_by_machine", {
            params: {
                machineId: searchData.machineId,
                from: searchData.from,
                to: searchData.to
            }
        })
    },

    async getFilteredOrdersReport(orderIds: Array<string>) {
        return await axios.post(BASE_URL() + "/orders_filtered_report", orderIds, {
            responseType: 'blob',
        });
    },

    async getOrder(id: string) {
        return await axios.get(BASE_URL() + "/orders", {
            params: {
                id
            }
        });
    },

    async saveOrder(order: OrderRequest) {
        return await axios.put(BASE_URL()+ "/orders", order)
    },

    async deleteOrder(data: { orderIds: Array<string>, deleteReason: string }) {
        return await axios.delete(BASE_URL()+ "/orders", {data})
    },

    async getReportOrder(id: string) {
        return await axios.get(BASE_URL() + "/order-report", {responseType: 'blob', params: {id: id}})
    },


    async closeOrder(data: CloseOrderRequest) {
        return await axios.post(BASE_URL()+ "/order-close", data)
    },

    async uploadClosedOrderFiles(order: OrderRequest): Promise<AxiosResponse> {
        let formData = new FormData()
        order.transferredFiles.forEach(file => {
            formData.append('files', file, file.name)
            formData.append('fileIds', file.id)
        })
        formData.append('orderId', order.id)

        return await axios.put(BASE_URL() + '/order_file', formData, {
            headers: {
                'Content-Type': 'multipart/form-data; boundary=file'
            }
        })
    },
    async getClosedOrderFile(filename: string): Promise<AxiosResponse> {
        return await axios.get(BASE_URL() + '/order_file', {responseType: 'blob', params: {filename: filename}});
    },
    async deleteOrderFile(fileId: string): Promise<AxiosResponse> {
        return await axios.delete(BASE_URL() + '/order_file', {data: {id: fileId}})
    },

    async getAutonomousOrders(period: {from: string, to: string}) {
        return await axios.get(BASE_URL() + "/autonomous_order", {
            params: {
                from: period.from,
                to: period.to
            }
        })
    },

    async getAutonomousMaintenanceReport(reportData: {machineIds: string, from: string, to: string}) {
        const {machineIds, from, to} = reportData
        return await axios.get(BASE_URL() + "/autonomous_maintenance_report", {
            responseType: 'blob',
            params: {
                machineIds,
                from,
                to
            }
        })
    },

    async deleteAutonomousOrders(orderIds: Array<string>): Promise<AxiosResponse> {
        return await axios.delete(BASE_URL() + '/autonomous_order', {data: {orderIds}})
    },

    async closeAutonomousOrder(data: { orderIds: Array<string>, observations: string }): Promise<AxiosResponse> {
        return await axios.post(BASE_URL() + '/close_autonomous_order', data)
    },

    //CONCEPTS
    async getConcepts() {
        return await axios.get(BASE_URL() + "/concepts");
    },

    async saveConcept(concept: ConceptRequest) {
        return await axios.put(BASE_URL() + "/concept", concept);
    },

    async deleteConcept(id: string) {
        return await axios.delete(BASE_URL() + "/concept", {data: {id:id}});
    },

    // PLANNINGS
    async saveMaintenancePlanning(planning: PlanningRequest) {
        return axios.put(BASE_URL() + "/maintenance_planning", planning)
    },

    async deleteMaintenancePlanning(planningId: string) {
        return axios.delete(BASE_URL() + '/maintenance_planning', {
            params: {
                planningId
            }
        })
    }
}
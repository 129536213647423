<template>
  <v-container fluid>
    <v-card class="mt-4">
      <v-container fluid>
        <v-row>
          <v-card-title>Imprimir Planillas</v-card-title>
          <v-spacer></v-spacer>
          <v-col md="4">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar Máquina"
              single-line
              hide-details
            />
          </v-col>
          <v-col md="2">
            <v-text-field
              v-model="monthName"
              :label="!month ? 'Elegir mes...' : ''"
              readonly
              @click="showCalendar = true"
            />
          </v-col>
          <v-col md="2">
            <v-btn
              :disabled="!month || !selectedMachines.length > 0" color="success" class="mt-2"
              @click="getMaintenanceReports"
            >
              <v-icon class="mr-2">mdi-printer</v-icon>
              Imprimir
            </v-btn>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        show-select
        @toggle-select-all="(checked) => {
          selectedMachines = checked.value
            ? [...getMachinesWithAutonomousByCode]
            : []
        }"
        v-model="selectedMachines"
        :headers="autonomousHeaders"
        :items="getMachinesWithAutonomousByCode"
        :items-per-page="10"
        :search="search"
        item-key="id"
        class="elevation-2"
      />
    </v-card>

    <DialogMonthCalendar
      month="" :show-calendar="showCalendar"
      @on-change-month="setMonth"
      @close-dialog="closeCalendar"
    />
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import DialogMonthCalendar from "@/components/base/DialogMonthCalendar.vue";

export default {
  name: 'PrintAutonomousMaintenance',
  components: {DialogMonthCalendar},
  data() {
    return {
      showCalendar: false,
      monthAndYear: '',
      month: '',
      monthName: '',
      search: '',
      selectedMachines: [],
      autonomousHeaders: [
        {text: 'Código', value: 'code.value'},
        {text: 'Descripción', value: 'description'},
      ]
    }
  },
  computed: {
    ...mapGetters({
      getMachines: 'machines/getMachines',
    }),
    getMachinesWithAutonomous() {
      return this.getMachines.filter(machine => {
        if (machine.plannings.length > 0) {
          return machine.plannings.some(planning => planning.isAutonomous)
        }
        return false
      })
    },
    getMachinesWithAutonomousByCode() {
      return this.getMachinesWithAutonomous.filter(machine => {
        return machine.code.value.includes(this.search)
      })
    }
  },
  async created() {
    await this.$store.dispatch('machines/getMachines')
  },
  methods: {
    setMonth(monthAndYear) {
      this.monthAndYear = monthAndYear
      this.month = monthAndYear.split('-')[1]
      this.monthName = moment(this.month, 'M', 'es').format('MMMM')

      this.showCalendar = false
    },
    closeCalendar() {
      this.showCalendar = false
    },
    async getMaintenanceReports() {
      const monthDays = moment(this.month).daysInMonth()
      const start = moment(this.monthAndYear)
      const from = start.format('YYYY-MM-DD')
      const to = start.add(monthDays - 1, 'days').format('YYYY-MM-DD')
      await this.$store.dispatch('autonomousOrders/getAutonomousMaintenanceReport', {
        machineIds: this.selectedMachines.map(machine => machine.id).join(','),
        from: from,
        to: to
      })
    },
  }
}
</script>

<style scoped>

</style>
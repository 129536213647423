import {SectorRequest} from "@/services/Request/SectorRequest";
import {ActionContext} from "vuex";
import {State} from "@/store";
import BuildingMaintenanceApi from "@/services/BuildingMaintenanceApi";
import httpStatus, {OK} from "http-status";
import {AxiosResponse} from "axios";

export interface SectorsState {
  sectors: Array<SectorRequest>
}

type Context = ActionContext<SectorsState, State>

export default {
  namespaced: true,
  state: (): SectorsState => ({
    sectors: Array<SectorRequest>()
  }),

  mutations: {
    loadSectors: (state: SectorsState, sectors: Array<SectorRequest>): void => {
      state.sectors = sectors
    },
    addSector: (state: SectorsState, sector: SectorRequest): void => {
      state.sectors.push(sector)
    },
    updateSector: (state: SectorsState, data: {index: number, sector: SectorRequest}): void => {
      Object.assign(state.sectors[data.index], data.sector)
    },
    deleteSector: (state: SectorsState, index: number): void => {
      state.sectors.splice(index, 1)
    },
  },

  actions: {
    async getSectors(context: Context): Promise<void> {
      const res = await BuildingMaintenanceApi.getSectors()
      if (res.status === httpStatus.OK) context.commit('loadSectors', res.data)
    },
    async getSector(context: Context, sectorId: string): Promise<void> {
      const sector = await BuildingMaintenanceApi.getSector(sectorId)
      return sector.data
    },
    async createOrEditSector(context: Context, data: {sector: SectorRequest, index: number}): Promise<AxiosResponse> {
      const res = await BuildingMaintenanceApi.createOrEditSector(data.sector)
      if (res.status === httpStatus.CREATED) {
        data.index > -1
          ? context.commit('updateSector', data)
          : context.commit('addSector', data.sector)
      }
      return res
    },
    async deleteSector(context: Context, data: {sector: SectorRequest, index: number}): Promise<AxiosResponse> {
      const res = await BuildingMaintenanceApi.deleteSector(data.sector.id)
      if (res.status === httpStatus.OK) context.commit('deleteSector', data.index)
      return res
    }
  },

  getters: {
    getSectors: (state: SectorsState): Array<SectorRequest> => {
      return state.sectors
    }
  }
}
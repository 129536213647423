<template>

  <v-dialog v-model="showDialog" @keydown.esc="onCloseDialog" persistent :width="width">

    <v-card>
      <v-card-title class="base-form-card-title d-flex flex-row">
        <div>
          {{ cardTitle }}
        </div>

        <div class="align-right">
          <v-icon style="color: white" @click="onCloseDialog">mdi-close</v-icon>
        </div>

      </v-card-title>
      <v-card-text>

        <slot></slot>

      </v-card-text>
    </v-card>

  </v-dialog>

</template>

<script>
export default {
  name: "BaseForm",
  props: {
    showDialog: Boolean,
    cardTitle: String,
    width: {
      type: Number,
      default: 1900
    }
  },
  data() {
    return {}
  },

  methods: {
    onCloseDialog() {
      this.$emit('on-close-dialog')
    }
  }
}
</script>

<style scoped>
.base-form-card-title {
  background-color: #3f51b5;
  color: white;
}

.align-right{
  position: absolute;
  right: 0px;
  padding: 10px;
}
</style>
export const BASE_URL = () => {
    // return 'http://localhost:4006';

    if (process.env.NODE_ENV === 'development') {
        return 'http://localhost:4006';
    } else {
        return 'https://maintenance-backend.metalurgicabp.com.ar';
    }
}


<template>
  <div>
    <v-container fluid>
      <v-btn @click="newSector" color="primary" outlined>Nuevo</v-btn>
      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-card-title>Sectores</v-card-title>
            <v-spacer></v-spacer>
            <v-col md="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              />
            </v-col>
            <v-col md="2">
              <RefreshButton
                class="mt-3 mr-8"
                module="sectors"
                table="sectors"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-data-table
          :headers="headers"
          :items="getSectors"
          :items-per-page="10"
          :search="search"
          item-key="id"
          class="elevation-2"
        >
          <template v-slot:item.floor="{item}">
            {{ getFloor(item.floor.value) }}
          </template>
          <template v-slot:item.actions="{item}">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <BaseForm
        :showDialog="showDialog" :cardTitle="getCardTitle"
        @on-close-dialog="closeDialog"
      >
        <SectorForm
          @on-close-dialog="closeDialog"
          :editedItem="editedItem" :editedIndex="editedIndex" :showDialog="showDialog"
        />
      </BaseForm>

      <DialogDelete
        :showDialogDelete="showDialogDelete"
        @delete-item="confirmDeleteItem"
        @on-close-delete-item="hideDialogDelete"
      />

    </v-container>
  </div>
</template>

<script>
import BaseForm from "@/components/base/BaseForm.vue";
import {mapGetters} from "vuex";
import DialogDelete from "@/components/base/DialogDelete.vue";
import {v4} from "uuid";
import httpStatus from "http-status";
import SectorForm from "@/components/building/SectorForm.vue";
import {getFloor} from "@/types/FloorTypes";
import RefreshButton from "@/components/base/RefreshButton.vue";

export default {
  name: "Sectors",
  components: {RefreshButton, SectorForm, BaseForm, DialogDelete},
  data() {
    return {
      search: '',
      showDialog: false,
      showDialogDelete: false,
      editedItem: '',
      editedIndex: -1,
      defaultItem: () => {
        return {
          id: v4(),
          description: '',
          floor: {
            value: ''
          },
          plannings: []
        }
      },
      headers: [
        {text: 'Descripción', value: 'description'},
        {text: 'Piso', value: 'floor'},
        {text: 'Acciones', value: 'actions'},
      ]
    }
  },

  computed: {
    ...mapGetters({
      getSectors: 'sectors/getSectors'
    }),
    getCardTitle() {
      return this.editedIndex > -1
        ? 'Editar Sector'
        : 'Nuevo Sector'
    },
  },

  async created() {
    await this.$store.dispatch('sectors/getSectors');
    this.editedItem = this.defaultItem()
  },

  methods: {
    getFloor,
    hideDialogDelete() {
      this.showDialogDelete = false;
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.showDialog = true;
      this.editedIndex = this.getSectors.indexOf(item);
    },

    deleteItem(item) {
      this.editedItem = item;
      this.editedIndex = this.getSectors.indexOf(item)
      this.showDialogDelete = !this.showDialogDelete;
    },

    async confirmDeleteItem() {
      const response = await this.$store.dispatch('sectors/deleteSector', {sector: this.editedItem, index: this.editedIndex});
      if (response.status === httpStatus.OK) {
        this.hideDialogDelete();
      }
    },

    newSector() {
      this.editedItem = this.defaultItem()
      this.editedIndex = -1;
      this.showDialog = true;
    },

    closeDialog() {
      this.showDialog = false;
    }
  }
}
</script>

<style scoped>
</style>
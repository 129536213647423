<template>
  <v-dialog v-model="showCalendar" width="300">
    <v-card>
      <v-container fluid>
        <v-card-text>
          <v-row justify="center">
            <v-date-picker
              type="month"
              @change="onMonthChange"
            ></v-date-picker>
          </v-row>
        </v-card-text>
        <v-row class="justify-center">
          <v-btn
            text class="my-3"
            @click="closeDialog"
          >
            Cerrar
          </v-btn>
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'DialogMonthCalendar',
  props: {
    showCalendar: Boolean,
    month: String
  },
  emits: ['on-change-month', 'close-dialog'],
  methods: {
    onMonthChange(date) {
      this.$emit('on-change-month', date)
    },
    closeDialog() {
      this.$emit('close-dialog')
    },
  },
}
</script>

<style scoped>

</style>
export const FloorTypes = {
    GroundFloor: {text: 'PB', value: '0'},
    FirstFloor: {text: '1', value: '1'},
    SecondFloor: {text: '2', value: '2'}
}

export const { GroundFloor, FirstFloor, SecondFloor } = FloorTypes

export function getFloor(value) {
    switch (value) {
        case GroundFloor.value:
            return GroundFloor.text
        case FirstFloor.value:
            return FirstFloor.text
        case SecondFloor.value:
            return SecondFloor.text
        default:
            return ''
    }
}
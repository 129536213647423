<template>
  <div>
    <v-container fluid>
      <v-tabs v-model="tab">
        <v-tab key="Características">Características</v-tab>
        <v-tab v-if="editedIndex > -1" key="Planificación">Planificación</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="Características">
          <v-form ref="sectorForm">
            <v-row class="mt-4">
              <v-col md="12">
                <v-text-field
                  autofocus
                  label="Descripción"
                  v-model="editedItem.description"
                  :rules="required"
                />
              </v-col>
            </v-row>
            <v-row md="1">
              <v-col md="3">
                <v-autocomplete
                  v-model="editedItem.floor.value"
                  :items="Object.values(FloorTypes)"
                  :value="floor"
                  :rules="required"
                  label="Piso"
                />
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>

        <v-tab-item key="Planificación">
          <v-data-table
            :headers="planningHeaders"
            :items="editedItem.plannings"
            :items-per-page="5"
            item-key="id"
            class="elevation-2"
          >
            <template v-slot:item.actions="{item}">
              <v-icon @click="editPlanning(item)">mdi-pencil</v-icon>
              <v-icon @click="onDeletePlanningClick(item)" class="ml-1">mdi-delete</v-icon>
            </template>
          </v-data-table>
          <v-card class="elevation-2">
            <v-container fluid>
              <v-form ref="planningForm" class="mt-4">
                <v-col md="6">
                  <v-autocomplete
                    v-model="concept"
                    :items="getBuildingConcepts"
                    item-text="title"
                    item-value="id"
                    :rules="requiredConcept"
                    label="Concepto"
                    return-object
                  />
                </v-col>
                <v-row>
                  <v-col md="2">
                    <v-text-field
                      v-model.number="maintenancePlanning.daysFrequency"
                      label="Frecuencia (días)"
                      type="number" min="1"
                      :rules="required"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-text-field
                      v-model.number="maintenancePlanning.employeesQuantity"
                      label="Cantidad de personas"
                      type="number" min="1"
                      :rules="required"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-text-field
                      v-model.number="maintenancePlanning.estimatedHours"
                      label="Horas de trabajo estimadas"
                      :rules="[workHoursRule, required].flat()"
                      type="number" min="0"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-text-field
                      v-model.number="maintenancePlanning.startDay"
                      label="Día de inicio"
                      type="number" min="1"
                      :rules="[validateDay, required].flat()"
                      hint="Representa el día del mes en que comienza la actividad"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      color="primary" class="mt-2"
                      @click="savePlanning"
                    >
                      {{ this.planningIndex > -1 ? 'Editar' : 'Insertar' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-tab-item>
      </v-tabs-items>

      <v-btn
        v-if="tab === 0"
        @click="save"
        outlined class="mt-2"
      >
        Guardar
      </v-btn>
    </v-container>

    <DialogConfirm
      :show-dialog="showDeletePlanningConfirm" parent-data="¿Confirma eliminar el plan de mantenimiento?"
      @on-close-dialog="showDeletePlanningConfirm = false"
      @confirm-action="deletePlanning"
    />
  </div>
</template>

<script>

import httpStatus from "http-status";
import DialogDelete from "@/components/base/DialogDelete.vue";
import {FloorTypes} from "@/types/FloorTypes";
import {v4} from "uuid";
import {mapGetters} from "vuex";
import DialogConfirm from "@/components/base/DialogConfirm.vue";

const { getCountries } = require("country-list-spanish");
const arrayRange = (start, stop, step) =>
  Array.from(
    { length: (stop - start) / step + 1 },
    (value, index) => start + index * step
  ).reverse()

export default {
  name: "SectorForm",

  components: {DialogConfirm, DialogDelete},

  props: ['editedItem', 'editedIndex', 'showDialog'],

  data() {
    return {
      tab: null,
      showDeleteConfirm: false,
      showDeletePlanningConfirm: false,
      concept: {},
      maintenancePlanning: {},
      deletingPlanning: {},
      defaultPlanning: () => {
        return {
          id: v4(),
          sector: '',
          concept: '',
          daysFrequency: 1,
          employeesQuantity: 1,
          estimatedHours: 1,
          startDay: 1,
        }
      },
      planningIndex: -1,
      floors: [
        {name: 'PB', value: '0'},
        {name: '1', value: '1'},
        {name: '2', value: '2'},
      ],
      countries: getCountries(),
      years: [],
      required: [
        v => !!v || 'Este campo es obligatorio',
      ],
      requiredConcept: [
        concept => Object.keys(concept).length === 0 ? 'Debe ingresar un concepto' : true
      ],
      validateDay: [
        day => (day<1 || day >28) ? 'Debe ser un valor entre 1 y 28' : true
      ],
      workHoursRule: [
        hours => hours>0 ? true : 'La hora debe ser mayor a 0'
      ],
      planningHeaders: [
        {text: 'Concepto', value: 'concept.title' },
        {text: 'Frecuencia (dias)', value: 'daysFrequency'},
        {text: 'Día inicio', value: 'startDay'},
        {text: 'Acciones', value: 'actions'},
      ],
    }
  },

  computed: {
    ...mapGetters({
      getBuildingConcepts: 'buildingOrders/getBuildingConcepts',
    }),
    FloorTypes() {
      return FloorTypes
    },
    floor: function () {
      if (this.editedItem.floor !== undefined) {
        return this.editedItem.floor.value
      }else{
        this.editedItem.floor = {};
        this.editedItem.floor.value= ''
      }
    },
    getCurrentYear: () => {
      const date = new Date()
      return date.getFullYear()
    },
  },

  watch: {
    showDialog(trueVal) {
      if (trueVal) {
        this.$refs.sectorForm.resetValidation()
      }
    }
  },

  async created() {
    await this.$store.dispatch('buildingOrders/getBuildingConcepts')
    this.years = arrayRange(1950, this.getCurrentYear, 1)
    this.maintenancePlanning = this.defaultPlanning()
  },

  methods: {
    async savePlanning() {
      if (!this.$refs.planningForm.validate()) return;
      this.maintenancePlanning.concept = this.concept.id
      this.maintenancePlanning.sector = this.editedItem.id
      const response = await this.$store.dispatch('buildingOrders/saveBuildingMaintenancePlanning', this.maintenancePlanning);

      if (response.status === httpStatus.CREATED) {
        this.maintenancePlanning.concept = this.concept

        if (this.planningIndex > -1) {
          Object.assign(this.editedItem.plannings[this.planningIndex], this.maintenancePlanning)
        } else {
          this.editedItem.plannings.push(this.maintenancePlanning)
        }
        this.maintenancePlanning = this.defaultPlanning()
        this.concept = {}
        this.$refs.planningForm.resetValidation()
        this.planningIndex = -1
      }
    },
    editPlanning(planning) {
      Object.assign(this.maintenancePlanning, planning)
      this.planningIndex = this.editedItem.plannings.indexOf(planning)
      this.concept = this.maintenancePlanning.concept
    },
    onDeletePlanningClick(planning) {
      this.deletingPlanning = planning
      this.showDeletePlanningConfirm = true
    },
    async deletePlanning() {
      const response = await this.$store.dispatch('buildingOrders/deleteBuildingMaintenancePlanning', this.deletingPlanning.id)
      if (response.status === httpStatus.OK) {
        this.editedItem.plannings.splice(this.editedItem.plannings.indexOf(this.deletingPlanning), 1)
        this.maintenancePlanning = this.defaultPlanning()
        this.$refs.planningForm.resetValidation()
        this.planningIndex = -1
      }

      this.showDeletePlanningConfirm = false
    },

    async save() {
      if (!this.$refs.sectorForm.validate()) return;
      const response = await this.$store.dispatch('sectors/createOrEditSector', {
        sector: this.editedItem,
        index: this.editedIndex
      });

      if (response.status === httpStatus.CREATED) {
        this.onCloseDialog()
      }
    },
    onCloseDialog() {
      this.tab = 0
      this.$emit('on-close-dialog');
      this.$refs.sectorForm.resetValidation()
      if (this.$refs.planningForm) this.$refs.planningForm.resetValidation()
      this.maintenancePlanning = this.defaultPlanning()
    },
  }
}
</script>

<style scoped>

</style>
import {ActionContext} from "vuex";
import {State} from "@/store";
import axios, {AxiosResponse} from "axios";
import LoginApi from "@/services/LoginApi";
import ServicesApi from "@/services/ServicesApi";
import {EmployeeRequest} from "@/services/Request/EmployeeRequest";

export interface AppState {
    isAuthenticated: boolean,
    isLoading: boolean,
    showSnack: boolean,
    snackColor: string,
    snackMsg: string,
    alertShow: boolean,
    alertMessage: string,
    alertType: string,
    currencies: Array<string>
    suppliers: Array<any>
    employees: Array<EmployeeRequest>,
    username: string
}

type Context = ActionContext<AppState, State>;

export default {
    namespaced: true,
    state: (): AppState => ({
        isAuthenticated: ('true' === localStorage.getItem("authenticated")),
        isLoading: false,
        showSnack: false,
        snackColor: 'primary',
        snackMsg: '',
        alertShow: false,
        alertMessage: "",
        alertType: "",
        currencies: ["PES", "DOL"],
        suppliers: [],
        employees: [],
        username: ""
    }),

    mutations: {
        setAlertNotificaton(state: AppState, data: { show: boolean, msg: string, type?: "default" | "error" | "info" | "msg" }): void {
            state.alertMessage = data.msg;
            state.alertShow = data.show;
            data.type === undefined ? state.alertType = "error" : state.alertType = data.type;
        },

        setSnackMsg(state: AppState, msg: string): void {
            state.snackMsg = msg;
        },

        setSnackColor(state: AppState, color: string): void {
            state.snackColor = color;
        },

        showSnack(state: AppState): void {
            state.showSnack = true;
        },

        hideSnack(state: AppState): void {
            state.showSnack = false;
        },

        setAuthenticated(state: AppState, authenticated: boolean): void {
            state.isAuthenticated = authenticated;
        },

        requestStop(state: AppState): void {
            state.isLoading = false;
        },

        requestStart(state: AppState) {
            state.isLoading = true;
        },

        loadSuppliers(state: AppState, suppliers: Array<any>): void {
            state.suppliers = suppliers;
        },

        loadEmployees(state: AppState, employees: Array<any>): void {
            state.employees = employees;
        },
    },

    actions: {
        async logout(context: Context): Promise<any> {
            await localStorage.setItem('user-token', "");
            await localStorage.setItem('authenticated', 'false');

            context.commit('setAuthenticated', false);
        },

        async login(context: Context, data: any): Promise<AxiosResponse> {
            const response = await LoginApi.login(data);

            if (response.status === 200) {
                let token = response.data.token;
                axios.defaults.headers.common['apiKey'] = token;
                await localStorage.setItem('user-token', token);
                await localStorage.setItem('authenticated', 'true');

                context.state.username = data.user

                context.commit('setAuthenticated', true);
            }

            return response;
        },

        async getSuppliers(context: Context): Promise<AxiosResponse> {
            const response = await ServicesApi.getSuppliers();
            if (response.status === 200) {
                let data = JSON.parse(response.data);
                context.commit("loadSuppliers", data.data);
            }

            return response;
        },

        async getEmployees(context: Context): Promise<AxiosResponse> {
            const response = await ServicesApi.getEmployees();
            if (response.status === 200) {
                context.commit("loadEmployees", response.data);
            }

            return response;
        },
    },

    getters: {

        getSuppliers: (state: AppState): Array<string> => {
            return state.suppliers;
        },

        getEmployees: (state: AppState): Array<EmployeeRequest> => {
            return state.employees;
        },

        getCurrencies: (state: AppState): Array<string> => {
            return state.currencies;
        },

        getAlertShow: (state: AppState): boolean => {
            return state.alertShow;
        },

        getAlertMessage: (state: AppState): string => {
            return state.alertMessage;
        },

        getAlertType: (state: AppState): string => {
            return state.alertType;
        },

        getSnackMsg: (state: AppState): string => {
            return state.snackMsg;
        },

        getSnackColor: (state: AppState): string => {
            return state.snackColor;
        },

        showSnack: (state: AppState): boolean => {
            return state.showSnack;
        },

        isAuthenticated: (state: AppState): boolean => {
            return state.isAuthenticated;
        },

        isLoading: (state: AppState): boolean => {
            return state.isLoading;
        },

        getUserName: (state: AppState): string => {
            return state.username;
        },
    }
}

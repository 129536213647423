<template>
    <v-row>
      <v-col md="7">
        <v-text-field
            v-model="money.amount"
            :label="label"
        ></v-text-field>
      </v-col>

      <v-col md="5">
        <v-autocomplete
            v-model="money.currency.value"
            label="Moneda"
            :items="getCurrencies"
        ></v-autocomplete>
      </v-col>
    </v-row>



</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "Money",

  props: ["money", "label"],

  computed: {
    ...mapGetters({
      getCurrencies: 'app/getCurrencies'
    })
  },
}
</script>

<style scoped>
.spaced{
  margin-left: 10px;
}
</style>
<template>
  <div>
    <v-container fluid>
      <v-btn @click="newMachine" color="primary" outlined>Nueva</v-btn>
      <v-btn class="ml-3" @click="getMachinesReport" color="primary" outlined>
        <v-icon class="mr-2">
          mdi-download
        </v-icon>
        Descargar Listado
      </v-btn>
      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-card-title>Máquinas</v-card-title>
            <v-spacer></v-spacer>
            <v-col md="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              />
            </v-col>
            <v-col md="1" class="mt-3">
              <v-menu
                :close-on-content-click="false"
                bottom
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    rounded
                    color="secondary"
                    dark
                    v-on="on"
                    prepend-icon="mdi-calendar"
                  >
                    <v-icon
                      dark
                    >
                      mdi-filter-variant
                    </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-container fluid>
                    <v-row>
                      <v-col md="6">
                        <v-card-subtitle>
                          Criticidad
                        </v-card-subtitle>
                        <v-divider></v-divider>
                        <v-radio-group v-model="criticality" mandatory>
                          <v-radio label="Todas"/>
                          <v-radio value="critical" label="Críticas"/>
                          <v-radio value="noCritical" label="No Críticas"/>
                        </v-radio-group>
                      </v-col>
                      <v-col md="6">
                        <v-card-subtitle>
                          Uso
                        </v-card-subtitle>
                        <v-divider></v-divider>
                        <v-radio-group v-model="machineUse" mandatory>
                          <v-radio label="Todas"/>
                          <v-radio value="using" label="En uso"/>
                          <v-radio value="notUsing" label="Sin uso"/>
                        </v-radio-group>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-card>
              </v-menu>
            </v-col>
            <v-col md="2">
              <RefreshButton
                class="mt-3 mr-8"
                module="machines"
                table="machines"
              />
            </v-col>
          </v-row>
        </v-container>

        <v-data-table
            :headers="headers"
            :items="getFilteredMachines"
            :items-per-page="10"
            :search="search"
            item-key="id"
            class="elevation-2"
        >
          <template v-slot:item.actions="{item}">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-card>

      <BaseForm :showDialog="showDialog" :cardTitle="getCardTitle" @on-close-dialog="closeDialog">
        <MachineForm
          :editedItem="editedItem" :editedIndex="editedIndex"
          @on-close-dialog="closeDialog"
        />
      </BaseForm>

      <DialogDelete
        :showDialogDelete="showDialogDelete"
        @delete-item="confirmDeleteItem"
        @on-close-delete-item="hideDialogDelete"
      ></DialogDelete>

    </v-container>
  </div>
</template>

<script>
import MachineForm from "../../components/machines/MachineForm.vue";
import BaseForm from "@/components/base/BaseForm.vue";
import {mapGetters} from "vuex";
import DialogDelete from "@/components/base/DialogDelete.vue";
import {v4} from "uuid";
import httpStatus from "http-status";
import RefreshButton from "@/components/base/RefreshButton.vue";

export default {
  name: "Machines",
  components: {RefreshButton, MachineForm, BaseForm, DialogDelete},
  data() {
    return {
      search: '',
      showDialog: false,
      showDialogDelete: false,
      editedItem: '',
      editedIndex: -1,
      defaultItem: () => {
        return {
          id: v4(),
          code: {
            value: ''
          },
          description: '',
          brand: '',
          model: '',
          weight: '',
          floor: {
            value: ''
          },
          ship: '',
          critical: true,
          observations: '',
          noLongerInUse: false,
          transferredFile: '',
          files: [],
          file: {
            id: v4(),
            name: {
              value: ''
            }
          },
          installedPotency: '',
          originCountry: '',
          year: '',
          serverRoute: '',
          parts: [],
          plannings: [],
        }
      },
      criticality: '',
      machineUse: '',
      headers: [
        {text: 'Código', value: 'code.value'},
        {text: 'Descripción', value: 'description'},
        {text: 'Marca', value: 'brand'},
        {text: 'Modelo', value: 'model'},
        {text: 'Acciones', value: 'actions'},
      ]
    }
  },

  computed: {
    ...mapGetters({
      getMachines: 'machines/getMachines'
    }),
    getCardTitle() {
      return this.editedIndex > -1
        ? 'Editar Máquina'
        : 'Nueva Máquina'
    },
    getFilteredMachines() {
      let machines = this.getMachines

      switch (this.criticality) {
        case 'critical':
          machines = machines.filter(machine => machine.critical)
          break
        case 'noCritical':
          machines = machines.filter(machine => !machine.critical)
          break
        default:
          break
      }

      switch (this.machineUse) {
        case 'using':
          machines = machines.filter(machine => !machine.noLongerInUse)
          break
        case 'notUsing':
          machines = machines.filter(machine => machine.noLongerInUse)
          break
        default:
          break
      }

      return machines
    }
  },

  async created() {
    await this.$store.dispatch('machines/getMachines');
    await this.$store.dispatch('parts/getParts');
    this.editedItem = this.defaultItem()
  },

  methods: {
    async getMachinesReport() {
      await this.$store.dispatch('machines/getMachinesReport')
    },
    newMachine() {
      this.editedItem = this.defaultItem()
      this.editedIndex = -1;
      this.showDialog = true;
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.showDialog = true;
      this.editedIndex = this.getMachines.indexOf(item);
    },
    deleteItem(item) {
      this.editedItem = item;
      this.showDialogDelete = !this.showDialogDelete;
    },
    async confirmDeleteItem() {
      const response = await this.$store.dispatch('machines/deleteMachine', {item: this.editedItem, index: this.editedIndex});
      if (response.status === httpStatus.OK) {
        this.hideDialogDelete();
      }
    },

    closeDialog() {
      this.showDialog = false;
    },
    hideDialogDelete() {
      this.showDialogDelete = false;
    },
  }
}
</script>

<style scoped>
.marked {
  background-color: #71c571;
}
</style>
<template>
  <div>
    <v-container fluid>
      <v-row>
        <v-col md="6">
          <v-container>
            <v-row>
              <v-col md="9">
                <v-text-field
                    readonly
                    :value="getMachineDescription"
                    label="Máquina"
                    :rules="required"
                />
              </v-col>
              <v-col md="3">
                <v-text-field
                    readonly
                    v-model="editedItem.scheduleDate"
                    label="Fecha Programada"
                />
              </v-col>
            </v-row>
            <v-form ref="closeOrderForm">
              <v-autocomplete
                multiple
                label="Responsables"
                v-model="editedItem.completedByArray"
                :items="getEmployees"
                item-text="nombre"
                :rules="requiredCompletedBy"
              />

              <div class="mt-3">
                <p class="mt-2">Fecha de comienzo de tarea</p>
                <input type="datetime-local" v-model="editedItem.startDateTime" class="dateFormat">
                <p
                  class="mt-1 red--text darken-3"
                  v-if="checkBadPeriod"
                >
                  La fecha y hora de comienzo deben ser menores que las de finalización
                </p>
              </div>
              <div class="mt-5">
                <p>Fecha de finalización de tarea</p>
                <input type="datetime-local" v-model="editedItem.endDateTime" class="dateFormat">
                <p
                  class="mt-1 red--text darken-3"
                  v-if="checkBadPeriod"
                >
                  La fecha y hora de comienzo deben ser menores que las de finalización
                </p>
              </div>

              <v-textarea
                  v-if="isCorrective"
                  class="mt-5"
                  label="Descripción de la tarea realizada"
                  v-model="editedItem.description"
              />

              <v-text-field
                class="mt-5"
                label="Observaciones"
                v-model="editedItem.observations"
              />
            </v-form>

            <v-btn color="success" class="mt-6" @click="closeOrder">
              {{ getCloseOrderText }}
              <v-icon dark class="ml-1">
                mdi-check-circle
              </v-icon>
            </v-btn>
          </v-container>
        </v-col>
        <v-divider vertical />
        <v-col md="6">
          <v-container>
            <v-col md="5">
              <v-form ref="upload">
                <v-file-input
                  ref="fileInput"
                  :rules="required"
                  :class="{'mb-3': editedItem.transferredFiles.length === 0}"
                  label="Subir archivo..." accept="*"
                  @change="setFiles"
                  @blur="() => $refs.upload.resetValidation()"
                />
              </v-form>
            </v-col>
            <v-row>
              <v-chip-group
                class="ml-2"
                v-for="file in editedItem.transferredFiles" v-if="editedItem.transferredFiles.length > 0"
                :key="file.name"
              >
                <v-chip
                  outlined close
                  color="primary"
                  class="mt-2 mb-8 ma-1"
                  @click:close="deleteTransferredFile(file)"
                >
                  {{ file.name }}
                </v-chip>
              </v-chip-group>
            </v-row>

            <v-btn color="primary" class="mt-2" @click="uploadFiles">
              Subir Archivos
              <v-icon dark class="ml-1">
                mdi-upload
              </v-icon>
            </v-btn>

            <v-card-subtitle>Archivos subidos</v-card-subtitle>
            <v-data-table
                style="width: 1000px"
                :headers="fileHeaders"
                :items="editedItem.files"
                :items-per-page="-1"
                class="elevation-1">

              <template v-slot:item.actions="{ item }">
                <v-icon color="success darken-1" class="mr-2 mt-1" @click="downloadFile(item)">mdi-download
                </v-icon>
                <v-icon color="red darken-4" @click="onDeleteClick(item)">mdi-delete</v-icon>
              </template>
            </v-data-table>
          </v-container>
        </v-col>
      </v-row>
    </v-container>

    <DialogDelete
      parent-data="Desea eliminar el archivo?"
      :show-dialog-delete="showDeleteConfirm"
      @on-close-delete-item="showDeleteConfirm = false"
      @delete-item="deleteFile"
    />
  </div>
</template>

<script>
import BaseForm from "@/components/base/BaseForm.vue";
import {mapGetters} from "vuex";
import DialogDelete from "@/components/base/DialogDelete.vue";
import FormDate from "@/components/base/FormDate.vue";
import httpStatus from "http-status";
import {v4} from "uuid";
import {CORRECTIVE_ORDER} from "@/types/OrderTypes";
import {FINISHED} from "@/types/OrderStates";

export default {
  name: "Orders",
  components: {BaseForm, DialogDelete, FormDate},
  data() {
    return {
      filePanel: [-1],
      showDeleteConfirm: false,
      tab: null,
      dateLabelStartWork: '',
      dateLabelEndWork: '',
      completedBy: '',
      selectedFile: {},
      required: [
        v => !!v || 'Este campo es obligatorio',
      ],
      requiredCompletedBy: [
        completedBy => completedBy?.length > 0 || 'Debe ingresar al menos un responsable'
      ],
      isRequired: true,
      fileHeaders: [
        { text: 'Archivo', value: 'name.value' },
        { text: 'Acciones', value: 'actions' },
      ]
    }
  },

  props: {
    editedItem: {
      type: Object,
      required: true
    },
    editedIndex: {
      type: Number,
      required: true
    }
  },

  computed: {
    ...mapGetters({
      getEmployees: 'app/getEmployees',
    }),

    getCloseOrderText() {
      return this.editedItem.orderState.value === FINISHED
        ? 'Editar Cierre'
        : 'Cerrar Orden'
    },

    getMachineDescription() {
      if (this.editedItem?.machine) {
        return this.editedItem.machine?.description
      }
      if (this.editedItem?.planning) {
        return this.editedItem.planning.machine.description
      }
     return ''
    },
    isCorrective: function () {
      return this.editedItem.type === CORRECTIVE_ORDER
    },
    checkBadPeriod() {
      const start = new Date(this.editedItem.startDateTime)
      const end = new Date(this.editedItem.endDateTime)
      return start.getTime() > end.getTime()
    },
  },

  async created() {
  },

  methods: {
    setFiles(file) {
      if (file) {
        file.id = v4()
        this.editedItem.transferredFiles.push(file)
        this.$refs.fileInput.reset()
        this.$refs.upload.resetValidation()
        this.$forceUpdate()
      }
    },
    deleteTransferredFile(file) {
      this.editedItem.transferredFiles.splice(this.editedItem.transferredFiles.indexOf(file), 1)
      this.$forceUpdate()
    },

    async closeOrder() {
      if (!this.$refs.closeOrderForm.validate() || this.checkBadPeriod) return;

      this.editedItem.completedBy = this.editedItem.completedByArray.join(', ')
      const response = await this.$store.dispatch('orders/closeOrder', this.editedItem);
      if (response.status === httpStatus.OK) this.$emit('on-save')
    },

    async uploadFiles() {
      if (!this.editedItem.transferredFiles.length > 0)
        if (!this.$refs.upload.validate()) return

      const response = await this.$store.dispatch('orders/uploadClosedOrderFiles', {editedIndex: this.editedIndex, item: this.editedItem});
      if (response.status === httpStatus.CREATED) {
        this.editedItem.file = {};
        this.editedItem.transferredFiles = []
        this.filePanel = [0]
        this.$refs.upload.resetValidation()
        this.$forceUpdate()
      }
    },
    async downloadFile(item) {
      await this.$store.dispatch('orders/getClosedOrderFile', item.name.value)
    },
    async onDeleteClick(file) {
      this.selectedFile = file
      this.showDeleteConfirm = true
    },
    async deleteFile() {
      const response = await this.$store.dispatch('orders/deleteOrderFile', this.selectedFile.id)
      if (response.status === httpStatus.OK) {
        this.editedItem.files.splice(this.editedItem.files.indexOf(this.selectedFile), 1)
        this.showDeleteConfirm = false
      }
    },
  }
}
</script>

<style scoped>
.dateFormat {
  border: 1px solid;
  margin-left: 1rem;
  padding: 5px 0;
}

</style>
<template>
  <v-layout row justify-center>
    <v-dialog v-model="showDialogDelete" persistent max-width="380">
      <v-form ref="formDelete">
        <v-card>
          <v-card-title class="headline">{{ parentData }}</v-card-title>
          <v-card-text>
            <v-text-field ref="reason" label="Detalle el motivo" :rules="required" v-model="deleteReason"></v-text-field>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green darken-1" text @click="confirm">Aceptar</v-btn>
            <v-btn color="green darken-1" text @click="dismiss">Cancelar</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-layout>
</template>

<script>

export default {
  data() {
    return {
      required: [
        v => !!v || 'Este campo es obligatorio',
      ],
      deleteReason: ''
    };
  },
  props: {
    parentData: {
      type: String,
      default() {
        return "Desea eliminar este registro?";
      }
    },
    showDialogDelete: false
  },

  watch: {
    showDialogDelete: function (newValue, oldValue) {
      if (newValue) {
        this.$nextTick(function () {
          this.$refs.reason.focus();
        });
      }
    },
  },

  methods: {
    dismiss() {
      this.$emit("on-close-delete-item");
    },

    confirm() {
      if (this.$refs.formDelete.validate()) {
        this.$emit("delete-item", this.deleteReason);
      }
    }
  }
};
</script>

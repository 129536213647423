<template>
  <v-layout row justify-center>
    <v-dialog v-model="showDialogDelete" persistent max-width="600">
      <v-card>
        <v-card-title class="headline">{{ parentData }}</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirm">Aceptar</v-btn>
          <v-btn color="green darken-1" text @click="dismiss">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>

export default {
  data() {
    return {
    };
  },
  props: {
    parentData: {
      type: String,
      default() {
        return "¿Desea eliminar este registro?";
      }
    },
    showDialogDelete: false
  },

  emits: ['on-close-delete-item', 'delete-item'],
  methods: {
    dismiss() {
      this.$emit("on-close-delete-item");
    },

    confirm() {
      this.$emit("delete-item");
    }
  }
};
</script>

<template>
  <v-container fluid>
    <v-form ref="conceptForm" class="mt-4">
      <v-text-field
        v-model="editedItem.title"
        label="Título"
        :rules="required"
      ></v-text-field>
      <v-textarea
        v-model="editedItem.description"
        label="Descripción"
      ></v-textarea>
    </v-form>
    <v-btn class="mt-2" color="primary" @click="save">Guardar</v-btn>
  </v-container>
</template>

<script>
import httpStatus from "http-status";

export default {
  name: "ConceptForm",
  props: {
    editedItem: {
      type: Object,
      required: true
    },
    editedIndex: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      required: [
        v => !!v || 'Este campo es obligatorio',
      ],
    }
  },

  methods: {
    async save() {
      if(!this.$refs.conceptForm.validate()) return;

      const response = await this.$store.dispatch('orders/saveConcept', this.editedItem)

      if (response.status === httpStatus.CREATED) {
        this.$refs.conceptForm.resetValidation()
        this.$emit('on-save')
      }
    },
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-menu
      ref="menu"
      v-model="menu"
      :close-on-content-click="false"
      :return-value.sync="date"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="selected"
          :rules="hasRules"
          v-on:input="$emit('on-change', $event)"
          :label="label"
          prepend-icon="mdi-calendar"
          readonly
          v-bind="attrs"
          v-on="on"
        />
      </template>
      <v-date-picker
          v-model="selected"
          no-title
          scrollable
      >
        <v-spacer></v-spacer>
        <v-btn
            text
            color="primary"
            @click="menu = false"
        >
          Cancel
        </v-btn>
        <v-btn
            text
            color="primary"
            @click="$refs.menu.save(date)"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>
  </div>

</template>

<script>
export default {
  name: "FormDate",
  props: {
    label: String,
    date: String,
    isRequired: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    menu: false,
    required: [
      v => !!v || 'Este campo es obligatorio',
    ],
  }),

  computed: {
    hasRules() {
      if (this.isRequired) {
        return this.required;
      } else {
        return [];
      }
    },
    selected: {
      get() {
        return this.date
      },
      set(value) {
        this.$emit('on-change', value)
      }
    }
  },

  methods: {
    onChange(value) {
      this.$emit("on-change", value)
    }
  }
}
</script>

<style scoped>

</style>
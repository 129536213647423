import {ActionContext} from "vuex";
import {State} from "@/store";
import {OrderRequest} from "@/services/Request/OrderRequest";
import MachinesApi from "@/services/MachinesApi";
import httpStatus from "http-status";
import {ConceptRequest} from "@/services/Request/ConceptRequest";
import {AxiosResponse} from "axios";
import {CloseOrderRequest} from "@/services/Request/CloseOrderRequest";
import {FileRequest} from "@/services/Request/FileRequest";
import {downloadFile} from "@/utils/FileDownloader";
import {PlanningRequest} from "@/services/Request/PlanningRequest";

export interface OrdersState {
    orders: Array<OrderRequest>,
    concepts: Array<ConceptRequest>
}

type Context = ActionContext<OrdersState, State>;

export default {
    namespaced: true,
    state: (): OrdersState => ({
        orders: Array<OrderRequest>(),
        concepts: Array<ConceptRequest>()
    }),

    mutations: {
        loadOrders: (state: OrdersState, orders: Array<OrderRequest>): void => {
            state.orders = orders;
        },

        loadConcepts: (state: OrdersState, concepts: Array<ConceptRequest>): void => {
            state.concepts = concepts;
        },

        addConcept: (state: OrdersState, concept: ConceptRequest): void => {
            let index = state.concepts.findIndex((element) => {
                return element.id === concept.id
            });

            if (index === -1) {
                state.concepts.push(concept);
            } else {
                Object.assign(state.concepts[index], concept);
            }
        },

        removeConcept: (state: OrdersState, concept: ConceptRequest): void => {
            let index = state.concepts.findIndex((element) => {
                return element.id === concept.id
            });

            state.concepts.splice(index, 1)
        },

        addOrder: (state: OrdersState, order: OrderRequest): void => {
            state.orders.push(order);
        },

        updateOrder: (state: OrdersState, order: OrderRequest): void => {
            let index = state.orders.findIndex((element) => {
                return element.id === order.id
            });

            Object.assign(state.orders[index], order);
        },

        deleteOrder: (state: OrdersState, data: { orderIds: Array<string>, deleteReason: string }): void => {
            data.orderIds.forEach(id => {
                let index = state.orders.findIndex((order) => {
                    return order.id === id
                });
                state.orders.splice(index, 1)
            })
        },

        addOrderFile(state: OrdersState, data: { editedIndex: number, order: OrderRequest }): void {
            // @ts-ignore
            data.order.transferredFiles.forEach(transferredFile => {
                const file: FileRequest = {
                    id: transferredFile.id,
                    name: {
                        value: transferredFile.name
                    }
                }
                data.order.files.push(file);
            })
        },
    },

    actions: {

        async saveSchedule(
            context: Context,
            data: { plannings: Array<PlanningRequest>, from: Date, to: Date }
        ): Promise<AxiosResponse> {
            return await MachinesApi.saveSchedule(data);
        },

        async closeOrder(context: Context, order: OrderRequest): Promise<AxiosResponse> {

            let data: CloseOrderRequest = {
                id: order.id,
                completedBy: order.completedBy,
                startDateTime: order.startDateTime,
                endDateTime: order.endDateTime,
                orderState: order.orderState,
                observations: order.observations,
                description: order.description
            }

            const response = await MachinesApi.closeOrder(data);

            if (response.status === httpStatus.OK) {
                context.commit('updateOrder', order)
            }

            return response;
        },

        async uploadClosedOrderFiles(context: Context, data: {
            editedIndex: number,
            item: OrderRequest
        }): Promise<AxiosResponse> {
            const response = await MachinesApi.uploadClosedOrderFiles(data.item)
            if (response.status === httpStatus.CREATED) {
                context.commit('addOrderFile', {
                    editedIndex: data.editedIndex, order: data.item
                })
            }
            return response
        },
        async getClosedOrderFile(context: Context, filename: string): Promise<void> {
            const response = await MachinesApi.getClosedOrderFile(filename);
            if (response.status === httpStatus.OK) {
                downloadFile(response.data, filename)
            }
        },
        async deleteOrderFile(context: Context, fileId: string): Promise<AxiosResponse> {
            return await MachinesApi.deleteOrderFile(fileId)
        },

        async getConcepts(context: Context): Promise<void> {
            const response = await MachinesApi.getConcepts();
            if (response.status === httpStatus.OK) {
                context.commit('loadConcepts', response.data)
            }
        },
        async saveConcept(context: Context, concept: ConceptRequest): Promise<AxiosResponse> {
            const response = await MachinesApi.saveConcept(concept);
            if (response.status === httpStatus.CREATED) {
                context.commit('addConcept', concept)
            }

            return response
        },
        async deleteConcept(context: Context, concept: ConceptRequest): Promise<AxiosResponse> {
            const response = await MachinesApi.deleteConcept(concept.id);
            if (response.status === httpStatus.OK) {
                context.commit('removeConcept', concept)
            }

            return response
        },

        async saveMaintenancePlanning(context: Context, planning: PlanningRequest) {
            return await MachinesApi.saveMaintenancePlanning(planning)
        },

        async deleteMaintenancePlanning(context: Context, planningId: string) {
            return await MachinesApi.deleteMaintenancePlanning(planningId)
        },

        async getOrders(context: Context, period: { from: string, to: string }): Promise<void> {
            const response = await MachinesApi.getOrders(period);
            if (response.status === httpStatus.OK) {
                context.commit('loadOrders', response.data)
            }
        },
        async getOrdersByMachine(context: Context, searchData: {
            machineId: string,
            from?: string,
            to?: string
        }): Promise<void> {
            const response = await MachinesApi.getOrdersByMachine(searchData);
            if (response.status === httpStatus.OK) {
                context.commit('loadOrders', response.data)
            }
        },
        async getOrder(context: Context, id: string) {
            const response = await MachinesApi.getOrder(id)
            return response.data
        },

        async saveOrder(context: Context, data: { editedIndex: number, item: OrderRequest }): Promise<AxiosResponse> {
            const response = await MachinesApi.saveOrder(data.item);

            if (response.status === 201) {
                let newOrder = JSON.parse(JSON.stringify(data.item));
                newOrder.number.value = response.data.number.value
                newOrder.planning = response.data.planning ?? ''
                newOrder.machine = response.data.machine

                if (data.editedIndex === -1) {
                    context.commit('addOrder', newOrder);
                } else {
                    context.commit('updateOrder', newOrder);
                }
            }

            return response;
        },

        async deleteOrder(context: Context, data: {
            orderIds: Array<string>,
            deleteReason: string
        }): Promise<AxiosResponse> {
            const response = await MachinesApi.deleteOrder(data);

            if (response.status === 200) {
                context.commit('deleteOrder', data)
            }

            return response;
        },

        async getReportOrder(context: Context, id: string): Promise<AxiosResponse> {

            const response = await MachinesApi.getReportOrder(id);
            if (response.status === 200) {
                let blob = new Blob([response.data], {type: 'application/pdf'})
                const blobURL = URL.createObjectURL(blob);
                window.open(blobURL);
            }

            return response;
        },
        async getFilteredOrdersReport(context: Context, orderIds: Array<string>): Promise<AxiosResponse> {
            const response = await MachinesApi.getFilteredOrdersReport(orderIds);
            if (response.status === 200) {
                let blob = new Blob([response.data], {type: 'application/pdf'})
                const blobURL = URL.createObjectURL(blob);
                window.open(blobURL);
            }

            return response;
        },
    },

    getters: {
        getOrders: (state: OrdersState): Array<OrderRequest> => {
            return state.orders;
        },
        getConcepts: (state: OrdersState): Array<ConceptRequest> => {
            return state.concepts;
        },
    }
}
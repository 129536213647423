import {ActionContext} from "vuex";
import {State} from "@/store";
import MachinesApi from "@/services/MachinesApi";
import {PartRequest} from "@/services/Request/PartRequest";
import httpStatus from "http-status";
import {AxiosResponse} from "axios";
import {MachineRequest} from "@/services/Request/MachineRequest";

export interface PartsState {
    parts: Array<PartRequest>
}

type Context = ActionContext<PartsState, State>;

export default {
    namespaced: true,
    state: (): PartsState => ({
        parts: Array<PartRequest>()
    }),

    mutations: {
        loadParts: (state: PartsState, parts: Array<PartRequest>): void => {
            state.parts = parts
        },

        addPart: (state: PartsState, part: PartRequest): void => {
            state.parts.push(part);
        },

        updatePart: (state: PartsState, data: { item: PartRequest, editedIndex: number }): void => {
            Object.assign(state.parts[data.editedIndex], data.item);
        },

        addMachineToPart: (state: PartsState, data: { editedIndex: number, machine: MachineRequest }): void => {
            state.parts[data.editedIndex].machines.push(data.machine);
        },

        deletePart: (state: PartsState, editedIndex: number ): void => {
            state.parts.splice(editedIndex, 1);
        },

        removeMachine: (state: PartsState, data: {part: PartRequest, machine: MachineRequest} ): void => {
            const index = data.part.machines.indexOf(data.machine);

            data.part.machines.splice(index, 1);
        },

    },

    actions: {

        async addMachine(context: Context, data: { editedIndex: number, machine: MachineRequest, part: PartRequest }): Promise<void> {
            const response = await MachinesApi.addMachineToPart({partId: data.part.id, machineId: data.machine.id});
            if (response.status === httpStatus.CREATED) {
                context.commit("addMachineToPart", {editedIndex: data.editedIndex, machine: data.machine});
            }
        },

        async getParts(context: Context): Promise<void> {
            const parts = await MachinesApi.getParts();
            context.commit('loadParts', parts);
        },

        async createPart(context: Context, data: { item: PartRequest, editedIndex: number }): Promise<AxiosResponse> {
            const response = await MachinesApi.createPart(data.item);

            if (response.status === httpStatus.CREATED) {
                if (data.editedIndex === -1) {
                    context.commit('addPart', data.item);
                } else {
                    context.commit("updatePart", data)
                }
            }
            return response;
        },

        async deletePart(context: Context, data: { item: PartRequest, editedIndex: number }): Promise<AxiosResponse> {
            const response = await MachinesApi.deletePart(data.item);

            if (response.status === httpStatus.OK) {
                context.commit("deletePart", data.editedIndex)
            }
            return response;
        },

        async removeMachine(context: Context, data: {part: PartRequest, machine: MachineRequest}): Promise<AxiosResponse> {
            const response = await MachinesApi.removeMachine(data);

            if (response.status === httpStatus.OK) {
                context.commit("removeMachine", data)
            }
            return response;
        }
    },

    getters: {
        getParts: (state: PartsState): Array<PartRequest> => {
            return state.parts;
        }
    }
}
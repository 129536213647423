<template>
  <div>
    <v-container fluid>
      <v-btn @click="newPart" color="primary" outlined>Nuevo</v-btn>
      <v-card class="mt-4">
        <v-container>
          <v-row>
            <v-card-title>Repuestos</v-card-title>
            <v-spacer></v-spacer>
            <v-col md="5">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Buscar"
                single-line
                hide-details
              />
            </v-col>
            <v-col md="1" class="mt-3">
              <v-menu
                :close-on-content-click="false"
                bottom
                transition="scale-transition"
                offset-y
                min-width="290px"
              >
                <template v-slot:activator="{ on }">
                  <v-btn
                    rounded
                    color="secondary"
                    dark
                    v-on="on"
                    prepend-icon="mdi-calendar"
                  >
                    <v-icon
                      dark
                    >
                      mdi-filter-variant
                    </v-icon>
                  </v-btn>
                </template>
                <v-card>
                  <v-container fluid>
                    <v-card-subtitle>
                      Criticidad
                    </v-card-subtitle>
                    <v-divider></v-divider>
                    <v-radio-group v-model="criticality" mandatory>
                      <v-radio label="Todos"/>
                      <v-radio value="critical" label="Críticos"/>
                      <v-radio value="noCritical" label="No Críticos"/>
                    </v-radio-group>
                  </v-container>
                </v-card>
              </v-menu>
            </v-col>

            <v-col md="2">
              <RefreshButton
                class="mt-3 mr-8"
                module="parts"
                table="parts"
              />
            </v-col>
          </v-row>
        </v-container>
        <v-data-table
          :headers="headers"
          :items="getFilteredParts"
          :items-per-page="10"
          :search="search"
          item-key="id"
          class="elevation-2"
        >
          <template v-slot:item.critical="{item}">
            {{ item.critical ? 'Sí' : 'No' }}
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)">
              mdi-delete
            </v-icon>
          </template>

        </v-data-table>

      </v-card>

      <BaseForm :showDialog="showDialog" :cardTitle="getCardTitle" @on-close-dialog="closeDialog">
        <PartForm @on-close-dialog="closeDialog" :editedItem="editedItem" :editedIndex="editedIndex"/>
      </BaseForm>

      <DialogDelete
          :showDialogDelete="showDialogDelete"
          @delete-item="confirmDeleteItem"
          @on-close-delete-item="hideDialogDelete"
      ></DialogDelete>

    </v-container>
  </div>
</template>

<script>
import PartForm from "@/components/machines/PartForm.vue"
import BaseForm from "@/components/base/BaseForm.vue";
import {mapGetters} from "vuex";
import DialogDelete from "@/components/base/DialogDelete.vue";
import {v4} from "uuid";
import httpStatus from "http-status";
import RefreshButton from "@/components/base/RefreshButton.vue";

export default {
  name: "Parts",
  components: {RefreshButton, PartForm, BaseForm, DialogDelete},
  data() {
    return {
      search: '',
      showDialog: false,
      showDialogDelete: false,
      criticality: '',
      editedItem: '',
      editedIndex: -1,
      defaultItem: () => {
        return {
          id: v4(),
          code: {
            value: ""
          },
          description: "",
          cost: {
            currency: {
              value: "PES"
            },
            amount: "0"
          },
          costDate: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          machines: [],
          critical: false,
          observations: "",
          supplier: ""
        }
      },
      headers: [
        {text: 'Código', value: 'code.value'},
        {text: 'Descripción', value: 'description'},
        {text: 'Proveedor', value: 'supplier'},
        {text: 'Acciones', value: 'actions'},
      ]
    }
  },

  computed: {
    ...mapGetters({
      getParts: 'parts/getParts'
    }),
    getCardTitle() {
      return this.editedIndex > -1
        ? 'Editar Repuesto'
        : 'Nuevo Repuesto'
    },
    getFilteredParts() {
      switch (this.criticality) {
        case 'critical':
          return this.getParts.filter(part => part.critical)
        case 'noCritical':
          return this.getParts.filter(part => !part.critical)
        default:
          return this.getParts
      }
    }
  },

  async created() {
    await this.$store.dispatch('parts/getParts');
    await this.$store.dispatch('machines/getMachines');
  },

  methods: {

    hideDialogDelete() {
      this.showDialogDelete = false;
    },

    editItem(item) {
      this.editedItem = Object.assign({}, item);
      this.showDialog = true;
      this.editedIndex = this.getParts.indexOf(item);
    },

    deleteItem(item) {
      this.editedItem = item;
      this.showDialogDelete = !this.showDialogDelete;
    },

    async confirmDeleteItem() {
      const response = await this.$store.dispatch('parts/deletePart', {
        item: this.editedItem,
        editedIndex: this.editedIndex
      });
      if (response.status === httpStatus.OK) {
        this.hideDialogDelete();
      }
    },

    newPart() {
      this.editedItem = this.defaultItem()
      this.editedIndex = -1;
      this.showDialog = true;
    },

    closeDialog() {
      this.showDialog = false;
    }
  }
}
</script>

<style scoped>

</style>
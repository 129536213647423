<template>
  <v-snackbar v-model="getShowSnack" :color="getSnackColor" :timeout="10000">
    {{getSnackMsg}}
    <v-btn text class="white--text" @click.native="getShowSnack = false">Cerrar</v-btn>
  </v-snackbar>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'SnackBar',
  data () {
    return {
      show: true
    }
  },
  computed: {
    ...mapGetters({
      showSnack: 'app/showSnack',
      getSnackColor: 'app/getSnackColor',
      getSnackMsg: 'app/getSnackMsg'
    }),

    getShowSnack: {
      get: function () {
        return this.showSnack;
      },
      set: function () {
        this.$store.commit('app/hideSnack');
      }
    }
  }
}
</script>
import Vue from 'vue'
import Vuex from 'vuex'
import machinesModule, {MachinesState} from "@/store/modules/machinesModule";
import appModule, {AppState} from "@/store/modules/appModule";
import partsModule, {PartsState} from "@/store/modules/partsModule";
import ordersModule, {OrdersState} from "@/store/modules/ordersModule";
import sectorsModule, {SectorsState} from "@/store/modules/sectorsModule";
import buildingOrdersModule, {BuildingOrdersState} from "@/store/modules/buildingOrdersModule";
import autonomousOrdersModule, {AutonomousOrdersState} from "@/store/modules/autonomousOrdersModule";

Vue.use(Vuex)

export interface State {
  machines: MachinesState;
  app: AppState;
  parts: PartsState;
  orders: OrdersState;
  autonomousOrders: AutonomousOrdersState;
  sectors: SectorsState;
  buildingOrders: BuildingOrdersState;
}

export default new Vuex.Store<State>({
  modules: {
    machines: machinesModule,
    app: appModule,
    parts: partsModule,
    orders: ordersModule,
    autonomousOrders: autonomousOrdersModule,
    sectors: sectorsModule,
    buildingOrders: buildingOrdersModule
  }
})

<template>
  <div>
    <v-navigation-drawer
        :clipped="clipped"
        v-model="show"
        dark
        class="primary"
        enable-resize-watcher
        app
        width="450"
    >
      <v-toolbar flat>
        <v-list-item>

          <v-list-item-content>
            <v-list-item-title class="title">Metalurgica BP - Mantenimiento -</v-list-item-title>
            <v-divider/>
            <v-list-item-subtitle>
              Usuario: {{getUsername}}
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

      </v-toolbar>

      <v-card-text>

        <v-treeview :items="items" open-on-click hoverable item-key="name">

          <template slot="prepend" slot-scope="{ item }">
            <v-icon v-text="item.icon"></v-icon>
          </template>

          <template slot="label" slot-scope="{ item }">
            <a class="linkNav" v-if="!item.path">{{ item.name }}</a>
            <router-link v-if="item.path" :to="item.path || '#'">
              <a class="linkNav" @click="hideMenu()">{{ item.name }}</a>
            </router-link>
          </template>

        </v-treeview>

      </v-card-text>
      <v-divider></v-divider>
      <v-list>
        <v-list-item @click="logout">
          <v-icon class="mr-2">mdi-power</v-icon>

          <v-list-item-title class="">
            Cerrar Sesión
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>


    <v-app-bar-nav-icon @click.stop="show = !show">
      <v-icon color="primary">mdi-menu</v-icon>
    </v-app-bar-nav-icon>


  </div>
</template>

<script>

import {mapGetters} from "vuex";

export default {

  computed: {
    ...mapGetters({
      getUsername: 'app/getUserName'
    })
  },

  methods: {
    async logout() {
      await this.$store.dispatch('app/logout');
      await this.$router.push('/login');
    },

    hideMenu() {
      this.show = false;
    }
  },

  data() {
    return {
      show: true,
      clipped: false,
      items: [
        {
          name: "Mantenimiento Máquinas",
          icon: "mdi-cog",
          children:[
            {
              name: "Máquinas",
              icon: "mdi-engine",
              path: "/machines",
            },
            {
              name: "Repuestos",
              icon: "mdi-tools",
              path: "/parts",
            },
            {
              name: "Conceptos",
              icon: "mdi-wrench-clock",
              path: "/concepts",
            },
            {
              name: "Planificación",
              icon: "mdi-book-clock-outline",
              path: "/schedule-maintenance",
            },
            {
              name: "Órdenes",
              icon: "mdi-format-list-bulleted",
              path: "/orders",
            },
            {
              name: "Mant. Autónomo",
              icon: "mdi-cog",
              children: [
                {
                  name: "Cierre",
                  icon: "mdi-checkbox-multiple-outline",
                  path: "/close-autonomous-maintenance",
                },
                {
                  name: "Imprimir planillas",
                  icon: "mdi-note-text",
                  path: "/print-autonomous-maintenance",
                },
              ]
            },
          ],
        },
        {
          name: "Mantenimiento Edilicio",
          icon: "mdi-domain",
          children: [
            {
              name: "Sectores",
              icon: "mdi-domain",
              path: "/sectors",
            },
            {
              name: "Conceptos",
              icon: "mdi-wrench-clock",
              path: "/building-concepts",
            },
            {
              name: "Planificación",
              icon: "mdi-book-clock-outline",
              path: "/building-schedule-maintenance",
            },
            {
              name: "Órdenes",
              icon: "mdi-format-list-bulleted",
              path: "/building-orders",
            },
          ]
        },
      ]
    }
  }
}
;
</script>

<style scoped>
.linkNav {
  text-decoration: none;
  color: #ffffff;
}
</style>

<template>
  <v-container fluid>
    <v-btn @click="onShowDialog" color="primary" outlined>Nuevo</v-btn>

    <v-card class="mt-4">
      <v-container fluid>
        <v-row>
          <v-card-title>Conceptos Mant. Edilicio</v-card-title>
          <v-spacer></v-spacer>

          <v-col md="5">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            ></v-text-field>
          </v-col>
          <v-col md="2">
            <RefreshButton
              class="mt-3 mr-8"
              module="buildingOrders"
              table="buildingConcepts"
            />
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        :headers="headers"
        :items="getConcepts"
        :items-per-page="10"
        :search="search"
        item-key="id"
        class="elevation-2"
      >
        <template v-slot:item.actions="{item}" class="elevation-1">
          <v-icon class="mr-2" @click="editItem(item)">
            mdi-pencil
          </v-icon>
          <v-icon @click="onDeleteClick(item)">
            mdi-delete
          </v-icon>
        </template>

      </v-data-table>
    </v-card>

    <BaseForm :showDialog="showDialog" :cardTitle="getCardTitleConcept" @on-close-dialog="closeDialogBaseForm">
      <BuildingConceptForm :editedItem="editedItem" :editedIndex="editedIndex" @on-save="closeDialogConceptForm"/>
    </BaseForm>

    <DialogDelete
      :showDialogDelete="showDialogDelete"
      @delete-item="confirmDeleteItem"
      @on-close-delete-item="showDialogDelete = false"
    />

  </v-container>
</template>

<script>
import {v4} from "uuid";
import {mapGetters} from "vuex";
import BaseForm from "@/components/base/BaseForm.vue";
import ConceptForm from "@/components/machines/ConceptForm.vue";
import RefreshButton from "@/components/base/RefreshButton.vue";
import DialogDelete from "@/components/base/DialogDelete.vue";
import BuildingConceptForm from "@/components/building/BuildingConceptForm.vue";

export default {
  name: "BuildingConcepts",
  components: {BuildingConceptForm, DialogDelete, RefreshButton, BaseForm, ConceptForm},
  data: function () {
    return {
      showDialog: false,
      showDialogDelete: false,
      search: '',
      headers: [
        {text: 'Concepto', value: 'title'},
        {text: 'Descripción', value: 'description'},
        {text: 'Acciones', value: 'actions'},
      ],
      editedItem: '',
      editedIndex: -1,
      defaultItem: () => ({
        id: v4(),
        title: '',
        description: '',
      }),
    }
  },

  computed: {
    ...mapGetters({
      getConcepts: 'buildingOrders/getBuildingConcepts'
    }),
    getCardTitleConcept() {
      return this.editedIndex > -1
        ? 'Editar concepto de mantenimiento'
        : 'Nuevo concepto de mantenimiento'
    }
  },

  async created() {
    await this.$store.dispatch('buildingOrders/getBuildingConcepts');
  },

  methods: {
    closeDialogConceptForm() {
      this.closeDialogBaseForm()
    },
    closeDialogBaseForm() {
      this.showDialog = false
    },

    onShowDialog() {
      this.editedItem = Object.assign({}, this.defaultItem())
      this.editedIndex = -1
      this.showDialog = true
    },

    editItem(item) {
      this.editedIndex = this.getConcepts.indexOf(item);
      this.editedItem = Object.assign({}, item)
      this.showDialog=true
    },

    async onDeleteClick(item) {
      this.editedIndex = this.getConcepts.indexOf(item)
      this.showDialogDelete = true
    },
    async confirmDeleteItem() {
      await this.$store.dispatch('buildingOrders/deleteBuildingConcept', this.getConcepts[this.editedIndex])
      this.showDialogDelete = false
    },

  }
}
</script>

<style scoped>

</style>
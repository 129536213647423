import {AutonomousOrderRequest} from "@/services/Request/AutonomousOrderRequest";
import {ActionContext} from "vuex";
import {State} from "@/store";
import MachinesApi from "@/services/MachinesApi";
import httpStatus from "http-status";

export interface AutonomousOrdersState {
  autonomousOrders: Array<AutonomousOrderRequest>,
}

type Context = ActionContext<AutonomousOrdersState, State>

export default {
  namespaced: true,
  state: (): AutonomousOrdersState => ({
    autonomousOrders: Array<AutonomousOrderRequest>()
  }),

  mutations: {
    loadAutonomousOrders: (state: AutonomousOrdersState, orders: Array<AutonomousOrderRequest>): void => {
      state.autonomousOrders = orders;
    },

    removeAutonomousOrders: (state: AutonomousOrdersState, orderIds: Array<string>): void => {
      orderIds.forEach(id => {
        const index = state.autonomousOrders.findIndex(order => order.id === id)
        state.autonomousOrders.splice(index, 1)
      })
    },
  },

  actions: {
    async getAutonomousOrders(context: Context, period: {from: string, to: string}): Promise<void> {
      const response = await MachinesApi.getAutonomousOrders(period)
      if (response.status === httpStatus.OK)
        context.commit('loadAutonomousOrders', response.data)
    },

    async getAutonomousMaintenanceReport(context: Context, reportData: {machineIds: string, from: string, to: string}): Promise<void> {
      const response = await MachinesApi.getAutonomousMaintenanceReport(reportData)
      if (response.status === 200) {
        let blob = new Blob([response.data], {type: 'application/pdf'})
        const blobURL = URL.createObjectURL(blob);
        window.open(blobURL);
      }
    },

    async deleteAutonomousOrders(context: Context, orderIds: Array<string>): Promise<void> {
      const response = await MachinesApi.deleteAutonomousOrders(orderIds)
      if (response.status === httpStatus.OK)
        context.commit('removeAutonomousOrders', orderIds)
    },

    async closeAutonomousOrder(context: Context, data: { orderIds: Array<string>, observations: string }): Promise<void> {
      const response = await MachinesApi.closeAutonomousOrder(data)
      if (response.status === httpStatus.OK)
        context.commit('removeAutonomousOrders', data.orderIds)
    },
  },

  getters: {
    getAutonomousOrders(state: AutonomousOrdersState): Array<AutonomousOrderRequest|[]> {
      return state.autonomousOrders
    },
    getAutonomousOrdersByCode(state: any) {
      return (code: string) => state.autonomousOrders.filter((order: any) => order.planning.machine?.code.value.toLowerCase().includes(code.toLowerCase()))
    }
  }
}
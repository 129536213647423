import axios, {AxiosResponse} from "axios";
import {BASE_URL} from "@/services/config";
import {SectorRequest} from "@/services/Request/SectorRequest";
import {CloseOrderRequest} from "@/services/Request/CloseOrderRequest";
import {BuildingOrderRequest} from "@/services/Request/BuildingOrderRequest";
import {BuildingConceptRequest} from "@/services/Request/BuildingConceptRequest";
import {BuildingPlanningRequest} from "@/services/Request/BuildingPlanningRequest";

export default {
  // SECTORS
  async getSectors(): Promise<AxiosResponse> {
    return  await axios.get(BASE_URL() + '/sector')
  },
  async getSector(sectorId: string): Promise<AxiosResponse> {
    return  await axios.get(BASE_URL() + '/sector', {
      params: {
        sectorId
      }
    })
  },
  async createOrEditSector(sector: SectorRequest): Promise<AxiosResponse> {
    return await axios.put(BASE_URL() + '/sector', sector)
  },
  async deleteSector(id: string): Promise<AxiosResponse> {
    return await axios.delete(BASE_URL() + '/sector', {
      data: {
        id
      }
    })
  },
  
  // ORDERS
  async saveBuildingSchedule(data: {plannings: Array<BuildingPlanningRequest>, from: Date, to: Date}) {
    const planningIds = data.plannings.map((planning) => {
      return planning.id;
    });

    return await axios.put(BASE_URL() + "/building_schedule_order",
      {planningIds: planningIds, from: data.from, to: data.to}
    )
  },

  async getBuildingOrders(period: {from: string, to: string}) {
    return await axios.get(BASE_URL() + "/building_order_by_period", {
      params: {
        from: period.from,
        to: period.to
      }
    });
  },

  async getBuildingOrder(id: string) {
    return await axios.get(BASE_URL() + "/building_order", {
      params: {
        id
      }
    });
  },

  async saveBuildingOrder(order: BuildingOrderRequest) {
    return await axios.put(BASE_URL()+ "/building_order", order)
  },

  async deleteBuildingOrder(data: { orderIds: Array<string>, deleteReason: string }) {
    return await axios.delete(BASE_URL()+ "/building_order", {data})
  },

  async getBuildingReportOrder(id: string) {
    return await axios.get(BASE_URL() + "/building_order_report", {responseType: 'blob', params: {id: id}})
  },

  async getFilteredBuildingOrdersReport(orderIds: Array<string>) {
    return await axios.post(
      BASE_URL() + "/building_orders_filtered_report", orderIds, {
      responseType: 'blob',
    })
  },

  async closeBuildingOrder(data: CloseOrderRequest) {
    return await axios.post(BASE_URL()+ "/building_order_close", data)
  },

  async uploadBuildingClosedOrderFiles(order: BuildingOrderRequest): Promise<AxiosResponse> {
    let formData = new FormData()
    order.transferredFiles.forEach(file => {
      formData.append('files', file, file.name)
      formData.append('fileIds', file.id)
    })
    formData.append('orderId', order.id)

    return await axios.put(BASE_URL() + '/building_order_file', formData, {
      headers: {
        'Content-Type': 'multipart/form-data; boundary=file'
      }
    })
  },
  async getBuildingClosedOrderFile(filename: string): Promise<AxiosResponse> {
    return await axios.get(BASE_URL() + '/building_order_file', {responseType: 'blob', params: {filename: filename}});
  },
  async deleteBuildingOrderFile(fileId: string): Promise<AxiosResponse> {
    return await axios.delete(BASE_URL() + '/building_order_file', {data: {id: fileId}})
  },

  //CONCEPTS
  async getBuildingConcepts() {
    return await axios.get(BASE_URL() + "/building_concept");
  },

  async saveBuildingConcept(concept: BuildingConceptRequest) {
    return await axios.put(BASE_URL() + "/building_concept", concept);
  },

  async deleteBuildingConcept(id: string) {
    return await axios.delete(BASE_URL() + "/building_concept", {data: {id:id}});
  },

  // PLANNINGS
  async saveBuildingMaintenancePlanning(planning: BuildingPlanningRequest) {
    return axios.put(BASE_URL() + "/building_planning", planning)
  },

  async deleteBuildingMaintenancePlanning(planningId: string) {
    return axios.delete(BASE_URL() + '/building_planning', {
      params: {
        planningId
      }
    })
  }
}
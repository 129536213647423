<template>
  <v-container fluid>
    <v-card class="mt-4">
      <v-container fluid>
        <v-row>
          <v-card-title>
            Cierre Mant. Autónomo
            <v-icon color="primary" class="ml-3" @click="searcherKey ++">mdi-history</v-icon>
          </v-card-title>
          <VSpacer></VSpacer>
          <SearcherByPeriod
            :to="to" :from="from" endpoint="autonomousOrders/getAutonomousOrders" :key="searcherKey"
            @set-after="setAfterPeriod"
            @set-before="setBeforePeriod"
            @set-custom-date="setCustomDate"
            @reset-list="resetList"
          />
          <v-col md="2" class="mt-4">
            <v-icon
              v-if="selectedOrders.length > 0" color="red darken-3"
              @click="onDeleteClick"
            >
              mdi-trash-can
            </v-icon>
            <v-icon
              v-if="selectedOrders.length > 0" color="success darken-1" class="ml-4"
              @click="onCloseOrders"
            >
              mdi-checkbox-multiple-marked
            </v-icon>
          </v-col>
          <v-col md="3">
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar por Código"
              single-line hide-details
            ></v-text-field>
          </v-col>
        </v-row>
      </v-container>

      <v-data-table
        v-model="selectedOrders"
        show-select sort sort-by="scheduleDate"
        @toggle-select-all="selectOrDeselectOrders"
        :headers="autonomousHeaders"
        :items="getAutonomousOrdersByCode(search)"
        :items-per-page="10"
        :page.sync="page"
        :search="search"
        item-key="id"
        class="elevation-2"
      >
        <template v-slot:item.scheduleDate="{item}" class="elevation-1">
          {{ getScheduleDate(item) }}
        </template>

        <template v-slot:item.actions="{item}" class="elevation-1">
          <v-icon @click="onDeleteClick(item)">
            mdi-delete
          </v-icon>
        </template>

      </v-data-table>
    </v-card>

    <DialogDelete
      :showDialogDelete="showDialogDelete"
      parent-data="¿Desea eliminar las ordenes seleccionadas?"
      @delete-item="confirmDeleteItem"
      @on-close-delete-item="showDialogDelete = false"
    />
    <DialogConfirm
      :show-dialog="showConfirmCloseOrder" :parent-data="dialogText" :with-observations="true"
      @on-close-dialog="showConfirmCloseOrder = false"
      @confirm-action="confirmCloseOrders"
    />

  </v-container>
</template>

<script>
import {mapGetters} from "vuex";
import RefreshButton from "@/components/base/RefreshButton.vue";
import DialogDelete from "@/components/base/DialogDelete.vue";
import moment from "moment";
import FormDate from "@/components/base/FormDate.vue";
import DialogConfirm from "@/components/base/DialogConfirm.vue";
import SearcherByPeriod from "@/components/base/SearcherByPeriod.vue";

export default {
  name: "CloseAutonomousMaintenance",
  components: {SearcherByPeriod, DialogConfirm, FormDate, DialogDelete, RefreshButton},
  data: function () {
    return {
      showDialog: false,
      showDialogDelete: false,
      showConfirmCloseOrder: false,
      page: 1,
      searcherKey: 0,
      dialogText: '',
      from: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      search: '',
      selectedOrders: [],
      autonomousHeaders: [
        {text: 'Programada', value: 'scheduleDate', width: 150},
        {text: 'Número', value: 'number.value', width: 100},
        {text: 'Cod.', value: 'planning.machine.code.value'},
        {text: 'Máquina', value: 'planning.machine.description'},
        {text: 'Concepto', value: 'planning.concept.title'},
      ],
      indexes: -1,
    }
  },

  computed: {
    ...mapGetters({
      getAutonomousOrdersByCode: 'autonomousOrders/getAutonomousOrdersByCode'
    }),
    getScheduleDate() {
      return order => moment(order.scheduleDate).format('DD/MM/YYYY')
    }
  },

  methods: {
    selectOrDeselectOrders(checked) {
      this.selectedOrders = checked.value
        ? [...this.getAutonomousOrdersByCode(this.search)]
        : []
    },

    setAfterPeriod() {
      if (this.from === this.to) {
        this.from = moment(this.from).add(1, 'days').format('YYYY-MM-DD')
        this.to = moment(this.to).add(7, 'days').format('YYYY-MM-DD')
      } else {
        this.from = moment(this.from).add(7, 'days').format('YYYY-MM-DD')
        this.to = moment(this.to).add(7, 'days').format('YYYY-MM-DD')
      }
    },
    setBeforePeriod() {
      if (this.from === this.to) {
        this.from = moment(this.from).subtract(7, 'days').format('YYYY-MM-DD')
        this.to = moment(this.to).subtract(1, 'days').format('YYYY-MM-DD')
      } else {
        this.from = moment(this.from).subtract(7, 'days').format('YYYY-MM-DD')
        this.to = moment(this.to).subtract(7, 'days').format('YYYY-MM-DD')
      }
    },
    setCustomDate(dates) {
      if (dates.length === 1)
        this.from = this.to = moment(dates[0]).format('YYYY-MM-DD')
      if (dates.length === 2) {
        this.from = dates[0]
        this.to = dates[1]
      }
      this.checkPeriod()
    },
    checkPeriod() {
      const from = new Date(this.from)
      const to = new Date(this.to)
      if (from.getTime() > to.getTime()) {
        [this.from, this.to] = [this.to, this.from]
      }
    },
    resetList() {
      this.$nextTick(() => {
        this.page = 1
      })
    },

    async onDeleteClick() {
      this.showDialogDelete = true
    },
    async confirmDeleteItem() {
      const orderIds = this.selectedOrders.map(order => order.id)
      await this.$store.dispatch('autonomousOrders/deleteAutonomousOrders', orderIds)
      this.showDialogDelete = false
      this.selectedOrders = []
    },
    onCloseOrders() {
      this.dialogText = '¿Confirma el cierre de las ordenes seleccionadas?'
      this.showConfirmCloseOrder = true
    },
    async confirmCloseOrders(observations) {
      const orderIds = this.selectedOrders.map(order => order.id)
      await this.$store.dispatch('autonomousOrders/closeAutonomousOrder', {orderIds: orderIds, observations: observations})
      this.showConfirmCloseOrder = false
      this.selectedOrders = []
    },
  }
}
</script>

<style scoped>

</style>
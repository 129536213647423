export const OrderStates = {
    PENDING: {text: 'Pendiente', value: 'pending', color: 'yellow darken-2', icon: 'mdi-information'},
    FINISHED: {text: 'Finalizada', value: 'finished', color: 'teal', icon: 'mdi-checkbox-marked-circle'},
    CANCELLED: {text: 'Cancelada', value: 'cancelled',  color: 'red', icon: 'mdi-close-circle'},
    EXPIRED: {text: 'Expirada', value: 'expired', color: 'orange darken-1', icon: 'mdi-alert'},
}
export const { PENDING, FINISHED, CANCELLED, EXPIRED } = OrderStates

export function getOrderState(stateVal) {
    const state = Object.values(OrderStates).filter(state => state.value.match(stateVal))
    return state[0]
}
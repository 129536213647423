<template>
  <v-dialog
    v-model="isLoading"
    persistent
    width="300"
  >
    <v-card>
      <v-card-title class="primary--text text-md">
        Cargando...
        <v-progress-circular
          indeterminate
          color="primary"
          class="mb-0 ml-4"
        ></v-progress-circular>
      </v-card-title>
    </v-card>
  </v-dialog>
</template>

<script>

import {mapGetters} from "vuex";

export default {
  name: 'LoadingIndicator',
  computed: {
    ...mapGetters({
      isLoading: 'app/isLoading'
    })
  }
}
</script>

<style scoped>

</style>
<template>
  <div>
    <v-container fluid>
      <v-tabs v-model="tab">
        <v-tab key="Características">Características</v-tab>
        <v-tab key="Repuestos">Repuestos</v-tab>
        <v-tab :disabled="editedItem.noLongerInUse" v-if="editedIndex > -1" key="Planificación">Planificación</v-tab>
        <v-tab key="Historial" v-if="editedIndex !== -1" @click="getOrdersHistory">Historial</v-tab>
        <v-tab key="Archivos" v-if="editedIndex !== -1">Archivos</v-tab>
      </v-tabs>

      <v-tabs-items v-model="tab">
        <v-tab-item key="Características">
          <v-form ref="machineForm">
            <v-row class="mt-4">
              <v-col md="4">
                <v-text-field
                  label="Código"
                  v-model="editedItem.code.value"
                  :rules="required"
                  @input="() => editedItem.code.value = editedItem.code.value.toUpperCase()"
                >
                </v-text-field>
              </v-col>
              <v-col md="8">
                <v-text-field
                  label="Descripción"
                  v-model="editedItem.description"
                  :rules="required"
                />
              </v-col>

              <v-col md="3">
                <v-text-field v-model="editedItem.brand" label="Marca"/>
              </v-col>
              <v-col md="3">
                <v-text-field v-model="editedItem.model" label="Modelo"/>
              </v-col>
              <v-col md="2">
                <v-text-field v-model="editedItem.weight" label="Peso (Kg.)" type="number"/>
              </v-col>
              <v-col md="2">
                <v-text-field v-model="editedItem.installedPotency" type="number"
                              label="Pot. Instalada (kW)"/>
              </v-col>
              <v-col md="1">
                <v-checkbox
                  v-model="editedItem.critical"
                  label="Es crítica"
                />
              </v-col>
              <v-col md="1">
                <v-checkbox v-model="editedItem.noLongerInUse" label="Fuera de uso"/>
              </v-col>

              <v-col md="1">
                <v-text-field v-model="editedItem.ship" label="Nave"/>
              </v-col>
              <v-col md="1">
                <v-autocomplete
                  v-model="editedItem.floor.value"
                  :value="floor"
                  label="Piso"
                  :items="floors"
                  item-text="name"
                  item-value="value"
                />
              </v-col>
              <v-col md="2">
                <v-autocomplete
                  v-model="editedItem.originCountry"
                  :items="countries"
                  label="País de origen"
                />
              </v-col>
              <v-col md="1">
                <v-autocomplete
                  v-model="editedItem.year"
                  :items="years"
                  label="Año"
                />
              </v-col>
              <v-col md="7">
                <v-text-field
                  ref="ruta"
                  v-model="editedItem.serverRoute"
                  label="Ruta server"
                >
                  <template v-slot:append-outer>
                    <v-btn outlined color="primary" @click="copyPath">Copiar</v-btn>
                  </template>
                </v-text-field>
              </v-col>
              <v-col md="12">
                <v-textarea v-model="editedItem.observations" label="Observaciones"/>
              </v-col>
            </v-row>
          </v-form>
        </v-tab-item>

        <v-tab-item key="Repuestos">
          <v-row>
            <v-col md="6">
              <v-form ref="partForm">
                <v-autocomplete
                  ref="partList"
                  label="Repuesto"
                  v-model="part"
                  :items="getParts"
                  item-text="description"
                  :rules="required"
                  return-object
                />
              </v-form>
            </v-col>
            <v-col md="2">
              <v-btn color="primary" class="mt-2" @click="addPart">Agregar</v-btn>
            </v-col>
          </v-row>

          <v-data-table
            :headers="partHeaders"
            :items="editedItem.parts"
            :items-per-page="5"
            item-key="id"
            class="elevation-2"
          >
            <template v-slot:item.delete="{item}" class="elevation-1">
              <v-icon @click="removePartMachine(item)">
                mdi-delete
              </v-icon>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item key="Planificación">
          <v-data-table
            :headers="planningHeaders"
            :items="editedItem.plannings"
            :items-per-page="10"
            item-key="id"
            sort-by="isAutonomous"
            class="elevation-2"
          >
            <template v-slot:item.isAutonomous="{item}">
              {{ item.isAutonomous ? 'Sí' : 'No' }}
            </template>
            <template v-slot:item.actions="{item}">
              <v-icon @click="editPlanning(item)">mdi-pencil</v-icon>
              <v-icon @click="onDeletePlanningClick(item)" class="ml-1">mdi-delete</v-icon>
            </template>
          </v-data-table>
          <v-card class="elevation-2">
            <v-container fluid>
              <v-form ref="planningForm" class="mt-4">
                <v-col md="6">
                  <v-autocomplete
                    v-model="concept"
                    :items="getConcepts"
                    item-text="title"
                    item-value="id"
                    :rules="requiredConcept"
                    label="Concepto"
                    return-object
                  />
                </v-col>
                <v-row>
                  <v-col md="2">
                    <v-text-field
                      v-model.number="maintenancePlanning.daysFrequency"
                      label="Frecuencia (días)"
                      type="number" min="1"
                      :rules="required"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-text-field
                      v-model.number="maintenancePlanning.employeesQuantity"
                      label="Cantidad de personas"
                      type="number" min="1"
                      :rules="required"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-text-field
                      v-model.number="maintenancePlanning.estimatedHours"
                      label="Horas de trabajo estimadas"
                      :rules="[workHoursRule, required].flat()"
                      type="number" min="0"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-text-field
                      v-model.number="maintenancePlanning.startDay"
                      label="Día de inicio"
                      type="number" min="1"
                      :rules="[validateDay, required].flat()"
                      hint="Representa el día del mes en que comienza la actividad"
                    ></v-text-field>
                  </v-col>
                  <v-col md="2">
                    <v-checkbox
                      v-model="maintenancePlanning.isAutonomous"
                      label="Es mantenimiento autónomo"
                    />
                  </v-col>
                  <v-col md="2">
                    <v-btn
                      color="primary" class="mt-2"
                      @click="savePlanning"
                    >
                      {{ this.planningIndex > -1 ? 'Editar' : 'Insertar' }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-form>
            </v-container>
          </v-card>
        </v-tab-item>

        <v-tab-item key="Historial">
          <v-row class="mt-1">
            <v-col md="3">
              <v-autocomplete
                v-model="periodOrderHistory"
                :items="getPeriods"
                item-text="name"
                item-value="value"
                label="Periodo"
                @change="getOrdersHistory"
              />
            </v-col>
            <SearcherByPeriod
              v-if="periodOrderHistory === 'betweenDates'"
              class="ml-4"
              :to="to" :from="from" :machine-id="editedItem.id"
              endpoint="orders/getOrdersByMachine"
              @set-custom-date="setHistoryPeriod"
              @reset-list="resetHistoryList"
            />
          </v-row>
          <v-data-table
            :headers="historyHeaders"
            :items="getOrders"
            :items-per-page="10"
            item-key="id"
            :page.sync="historyPage"
            class="elevation-2 mt-4"
          >
            <template v-slot:item.endDateTime="{item}">
              {{  moment(item.endDateTime).format('DD/MM/YYYY')  }}
            </template>
            <template v-slot:item.type="{item}">
              <span
                class="font-weight-bold"
                :class="{'blue--text': item.type === PREVENTIVE_ORDER(), 'red--text': item.type === CORRECTIVE_ORDER()}"
              >
                {{ getType(item) }}
              </span>
            </template>
            <template v-slot:item.activity="{item}">
              {{ getPerformedActivity(item) }}
            </template>
            <template v-slot:item.show="{item}">
              <v-icon small class="ma-2" @click="showOrderReport(item.id)">
                mdi-eye
              </v-icon>
            </template>
          </v-data-table>
        </v-tab-item>

        <v-tab-item key="Archivos">
          <v-row>
            <v-col md="5">
              <v-file-input ref="fileInput" v-model="editedItem.transferredFile" label="Subir archivo..." accept="*"/>
            </v-col>
            <v-col md="2">
              <v-btn outlined class="mt-2" @click="uploadFile">Subir Archivo</v-btn>
            </v-col>
          </v-row>

          <v-data-table
            style="width: 1200px"
            :headers="headers"
            :items="editedItem.files"
            :items-per-page="-1"
            class="elevation-1">

            <template
              v-slot:body="{ items }">
              <tbody>
              <tr
                v-for="item in items"
                :key="item.id">
                <td><a href="#" @click="downloadFile(item)">{{ item.name.value }}</a></td>
                <td>
                  <v-icon small @click="onDeleteClick(item)">
                    mdi-delete
                  </v-icon>
                </td>
              </tr>
              </tbody>
            </template>

          </v-data-table>

        </v-tab-item>

      </v-tabs-items>
      <v-btn
        v-if="tab === 0 || tab === 1"
        outlined class="mt-4 mb-2"
        @click="save"
      >
        Guardar
      </v-btn>

      <DialogDelete
        parent-data="Desea eliminar el archivo?"
        :show-dialog-delete="showDeleteConfirm"
        @on-close-delete-item="showDeleteConfirm = false"
        @delete-item="deleteFile"
      />
      <DialogConfirm
        :show-dialog="showDeletePlanningConfirm" parent-data="¿Confirma eliminar el plan de mantenimiento?"
        @on-close-dialog="showDeletePlanningConfirm = false"
        @confirm-action="deletePlanning"
      />
      <SnackBar />
    </v-container>
  </div>
</template>

<script>

import httpStatus from "http-status";
import {v4} from "uuid";
import DialogDelete from "@/components/base/DialogDelete.vue";
import {mapGetters} from "vuex";
import {CORRECTIVE_ORDER, PREVENTIVE_ORDER} from "@/types/OrderTypes";
import DialogConfirm from "@/components/base/DialogConfirm.vue";
import moment from "moment";
import SearcherByPeriod from "@/components/base/SearcherByPeriod.vue";
import SnackBar from "@/components/base/Snackbar.vue";

const {getCountries} = require("country-list-spanish");
const arrayRange = (start, stop, step) =>
  Array.from(
    {length: (stop - start) / step + 1},
    (value, index) => start + index * step
  ).reverse()

export default {
  name: "MachineForm",
  components: {SnackBar, SearcherByPeriod, DialogConfirm, DialogDelete},
  props: ['editedItem', 'editedIndex'],

  data() {
    return {
      tab: null,
      showDeleteConfirm: false,
      showDeletePlanningConfirm: false,
      showSnackBar: false,
      snackBarColor: '',
      snackBarMessage: '',
      selectedFile: {},
      fileIndex: -1,
      part: {},
      concept: {},
      required: [
        v => !!v || 'Este campo es obligatorio',
      ],
      requiredConcept: [
        concept => Object.keys(concept).length === 0 ? 'Debe ingresar un concepto' : true
      ],
      validateDay: [
        day => (day < 1 || day > 28) ? 'Debe ser un valor entre 1 y 28' : true
      ],
      workHoursRule: [
        hours => hours > 0 ? true : 'La hora debe ser mayor a 0'
      ],
      floors: [
        {name: 'PB', value: '0'},
        {name: '1', value: '1'},
        {name: '2', value: '2'},
      ],
      countries: getCountries(),
      years: [],
      defaultPlanning: () => {
        return {
          id: v4(),
          machine: '',
          concept: '',
          daysFrequency: 1,
          employeesQuantity: 1,
          estimatedHours: 1,
          startDay: 1,
          isAutonomous: false,
        }
      },
      maintenancePlanning: {},
      deletingPlanning: {},
      planningIndex: -1,
      periodOrderHistory: 'fromStart',
      historyPage: 1,
      from: moment().startOf('year').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      headers: [
        {text: 'Archivo', value: 'name.value'},
        {text: 'Borrar', value: 'id'},
      ],
      partHeaders: [
        {text: 'Código', value: 'code.value'},
        {text: 'Descripción', value: 'description'},
        {text: 'Borrar', value: 'delete'},
      ],
      planningHeaders: [
        {text: 'Concepto', value: 'concept.title'},
        {text: 'Frecuencia (dias)', value: 'daysFrequency'},
        {text: 'Día inicio', value: 'startDay'},
        {text: 'Es autónomo', value: 'isAutonomous'},
        {text: 'Acciones', value: 'actions'},
      ],
      historyHeaders: [
        {text: 'Fecha Cierre', value: 'endDateTime'},
        {text: 'Num.', value: 'number.value'},
        {text: 'Tipo', value: 'type', width: 100},
        {text: 'Actividad realizada', value: 'activity'},
        {text: 'Ver', value: 'show'},
      ],
    }
  },

  computed: {
    ...mapGetters({
      getParts: 'parts/getParts',
      getConcepts: 'orders/getConcepts',
      getOrders: 'orders/getOrders',
    }),

    floor: function () {
      if (this.editedItem.floor !== undefined) {
        return this.editedItem.floor.value
      } else {
        this.editedItem.floor = {};
        this.editedItem.floor.value = ''
      }
    },
    getCurrentYear: () => {
      const date = new Date()
      return date.getFullYear()
    },
    getPeriods() {
      return [
        {name: 'Desde el Comienzo', value: 'fromStart'},
        {name: 'Este Año', value: 'thisYear'},
        {name: 'Entre Fechas', value: 'betweenDates'},
      ]
    },
    getPerformedActivity() {
      return item => item.planning && item.planning.concept
        ? item.planning.concept.title ?? ''
        : item.description
    },
    getType() {
      return item => {
        if (item.type === PREVENTIVE_ORDER) {
          return 'P';
        }
        if (item.type === CORRECTIVE_ORDER) {
          return 'C';
        }
      }
    },
  },

  watch: {
    editedItem() {
        this.tab = 0
        this.periodOrderHistory = 'fromStart'
    }
  },

  async created() {
    await this.$store.dispatch('orders/getConcepts')
    this.years = arrayRange(1950, this.getCurrentYear, 1)
    this.maintenancePlanning = this.defaultPlanning()
  },

  methods: {
    moment,
    CORRECTIVE_ORDER() {
      return CORRECTIVE_ORDER
    },
    PREVENTIVE_ORDER() {
      return PREVENTIVE_ORDER
    },
    copyPath() {
      navigator.clipboard.writeText(this.$refs.ruta.$el.getElementsByTagName("input")[0].value)
      this.showSnackMessage('Ruta copiada!')
    },

    addPart() {
      if (!this.$refs.partForm.validate()) return
      this.editedItem.parts.push(this.part)
      this.$refs.partList.reset()
      this.$refs.partForm.resetValidation()
    },
    removePartMachine(part) {
      this.editedItem.parts.splice(this.editedItem.parts.indexOf(part), 1)
    },

    async onDeleteClick(file) {
      this.selectedFile = file
      this.fileIndex = this.editedItem.files.indexOf(this.selectedFile)
      this.showDeleteConfirm = true
    },
    async deleteFile() {
      const response = await this.$store.dispatch('machines/deleteMachineFile', {
        editedIndex: this.editedIndex,
        item: this.selectedFile
      })
      if (response.status === httpStatus.OK) {
        this.editedItem.files.splice(this.fileIndex, -1)
        this.showDeleteConfirm = false
      }
    },
    async downloadFile(item) {
      await this.$store.dispatch('machines/getMachineFile', item)
    },

    async save() {
      if (!this.$refs.machineForm.validate()) return;
      const response = await this.$store.dispatch('machines/createMachine', {
        item: this.editedItem,
        editedIndex: this.editedIndex
      });

      if (response.status === httpStatus.CREATED) {
        this.onCloseDialog()
      }
    },

    async savePlanning() {
      if (!this.$refs.planningForm.validate()) return;
      this.maintenancePlanning.concept = this.concept.id
      this.maintenancePlanning.machine = this.editedItem.id
      const response = await this.$store.dispatch('orders/saveMaintenancePlanning', this.maintenancePlanning);

      if (response.status === httpStatus.CREATED) {
        this.maintenancePlanning.concept = this.concept

        if (this.planningIndex > -1) {
          Object.assign(this.editedItem.plannings[this.planningIndex], this.maintenancePlanning)
        } else {
          this.editedItem.plannings.push(this.maintenancePlanning)
        }
        if (!this.maintenancePlanning.isAutonomous)
          this.editedItem.critical = true

        this.maintenancePlanning = this.defaultPlanning()
        this.concept = {}
        this.$refs.planningForm.resetValidation()
        this.planningIndex = -1
      }
    },
    editPlanning(planning) {
      this.planningIndex = this.editedItem.plannings.indexOf(planning)
      Object.assign(this.maintenancePlanning, planning)
      this.concept = this.maintenancePlanning.concept
    },
    onDeletePlanningClick(planning) {
      this.deletingPlanning = planning
      this.showDeletePlanningConfirm = true
    },
    async deletePlanning() {
      const response = await this.$store.dispatch('orders/deleteMaintenancePlanning', this.deletingPlanning.id)
      if (response.status === httpStatus.OK) {
        this.editedItem.plannings.splice(this.editedItem.plannings.indexOf(this.deletingPlanning), 1)
        this.maintenancePlanning = this.defaultPlanning()
        this.$refs.planningForm.resetValidation()
        this.planningIndex = -1
      }

      this.showDeletePlanningConfirm = false
    },

    async getOrdersHistory() {
      let searchOptions = {
        machineId: this.editedItem.id
      }
      if (this.periodOrderHistory === 'betweenDates') {
        return
      }
      if (this.periodOrderHistory === 'thisYear') {
        searchOptions.from = moment().startOf('year').format('YYYY-MM-DD')
        searchOptions.to = moment().format('YYYY-MM-DD')
      }
      await this.$store.dispatch('orders/getOrdersByMachine', searchOptions)
    },
    setHistoryPeriod(dates) {
      if (dates.length === 1)
        this.from = this.to = moment(dates[0]).format('YYYY-MM-DD')
      if (dates.length === 2) {
        this.from = dates[0]
        this.to = dates[1]
      }
      this.checkPeriod()
    },
    checkPeriod() {
      const from = new Date(this.from)
      const to = new Date(this.to)
      if (from.getTime() > to.getTime()) {
        [this.from, this.to] = [this.to, this.from]
      }
    },
    resetHistoryList() {
      this.$nextTick(() => {
        this.historyPage = 1
      })
    },
    async showOrderReport(id) {
      await this.$store.dispatch('orders/getReportOrder', id);
    },

    async uploadFile() {
      if (this.editedIndex === -1) {
        this.$store.commit('app/setSnackMsg', 'Primero debe guardar la máquina para subir los archivos');
        this.$store.commit('app/showSnack');
        return;
      }

      this.editedItem.file = {
        id: v4()
      }

      const response = await this.$store.dispatch('machines/uploadMachineFile', {
        editedIndex: this.editedIndex,
        item: this.editedItem
      });
      if (response.status === httpStatus.CREATED) {
        this.editedItem.file = [];
        this.editedItem.fileId = v4();
        this.$refs.fileInput.reset()
      }
    },

    onCloseDialog() {
      this.tab = 0
      this.$emit('on-close-dialog');
      this.$refs.machineForm.resetValidation()
      if (this.$refs.planningForm) this.$refs.planningForm.resetValidation()
      this.maintenancePlanning = this.defaultPlanning()
    },
    showSnackMessage(message) {
      this.$store.commit('app/showSnack');
      this.$store.commit('app/setSnackColor', 'primary');
      this.$store.commit('app/setSnackMsg', message);
    },
  }
}
</script>

<style scoped>

</style>
import {MachineRequest} from "@/services/Request/MachineRequest";
import {ActionContext} from "vuex";
import {State} from "@/store";
import MachinesApi from "@/services/MachinesApi";
import httpStatus from "http-status";
import {AxiosResponse} from "axios";
import {FileRequest} from "@/services/Request/FileRequest";
import {downloadFile} from "@/utils/FileDownloader";

export interface MachinesState {
    machines: Array<MachineRequest>
}

type Context = ActionContext<MachinesState, State>;

export default {
    namespaced: true,
    state: (): MachinesState => ({
        machines: Array<MachineRequest>()
    }),

    mutations: {
        loadMachines: (state: MachinesState, machines: Array<MachineRequest>): void => {
            state.machines = machines
        },

        addMachine: (state: MachinesState, machine: MachineRequest): void => {
            state.machines.push(machine);
        },

        updateMachine: (state: MachinesState, data: {editedIndex: number, item: MachineRequest}): void => {
            Object.assign(state.machines[data.editedIndex], data.item)
        },

        addMachineFile(state: MachinesState, data: { editedIndex: number, machine: MachineRequest  }): void {
            const file : FileRequest = {
                id: data.machine.file.id,
                name: {
                    value: data.machine.transferredFile.name
                }
            }
            state.machines[data.editedIndex].files.push(file);
        },

        deleteMachineFile(state: MachinesState, data: { editedIndex: number, item: FileRequest }): void {
            let machine = state.machines[data.editedIndex];
            let fileIndex = machine.files.indexOf(data.item);
            machine.files.splice(fileIndex, 1);
        },

        deleteMachine(state: MachinesState, index: number): void {
            state.machines.splice(index, 1);
        },
    },

    actions: {
        async deleteMachineFile(context: Context, data: {editedIndex: number, item: {id: string}}): Promise<AxiosResponse> {
            const response = await MachinesApi.deleteMachineFile(data.item.id);
            if (response.status === httpStatus.OK) {
                context.commit('deleteMachineFile', {editedIndex: data.editedIndex, item: data.item})
            }
            return response
        },

        async uploadMachineFile(context: Context, data: {editedIndex: number, item: MachineRequest}): Promise<AxiosResponse> {
            const response = await MachinesApi.uploadMachineFile(data.item);
            if (response.status === httpStatus.CREATED) {
                context.commit('addMachineFile', {editedIndex: data.editedIndex, machine: data.item});
            }

            return response;
        },

        async getMachineFile(context: Context, item: { id: string; name: { value: string; }; }): Promise<void> {
            const response = await MachinesApi.getMachineFile(item.id);
            if (response.status === httpStatus.OK) {
                downloadFile(response.data, item.name.value)
            }
        },

        async deleteMachine(context: Context, data: {item: MachineRequest, index: number}): Promise<AxiosResponse> {
            const response = await MachinesApi.deleteMachine(data.item.id);
            if (response.status === httpStatus.OK) {
                context.commit('deleteMachine', data.index);
            }
            return response;
        },

        async getMachines(context: Context): Promise<void> {
            const machines = await MachinesApi.getMachines();
            context.commit('loadMachines', machines);
        },

        async getMachine(context: Context, machineId: string): Promise<void> {
            return await MachinesApi.getMachine(machineId)
        },

        async getMachinesReport(context: Context): Promise<void> {
            const response = await MachinesApi.getMachinesReport()
            if (response.status === 200) {
                let blob = new Blob([response.data], {type: 'application/xlsx'})
                let fileName = 'Listado de maquinas.xls';
                let file = new File([blob], fileName)
                const blobUrl = URL.createObjectURL(file)

                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = fileName;
                a.style.display = 'none';

                document.body.appendChild(a);
                a.click();

                document.body.removeChild(a);
                URL.revokeObjectURL(blobUrl);
            }
        },

        async createMachine(context: Context, data: {editedIndex: number, item: MachineRequest}): Promise<AxiosResponse> {
            const response = await MachinesApi.createNewMachine(data.item);
            if (response.status === httpStatus.CREATED) {
                if (data.editedIndex === -1) {
                    context.commit('addMachine', data.item);
                } else {
                    context.commit('updateMachine', data)
                }
            }
            return response;
        }
    },

    getters: {
        getMachines: (state: MachinesState): Array<MachineRequest> => {
            return state.machines;
        }
    }
}
import axios from "axios";
import {BASE_URL} from "@/services/config";

export default {
    async getSuppliers(): Promise<any> {
        return await axios.get(BASE_URL() + '/suppliers');
    },

    async getEmployees(): Promise<any> {
        return await axios.get(BASE_URL() + '/employees');
    }
}
<template>
  <v-layout row justify-center>
    <v-dialog v-model="showDialog" persistent max-width="650">
      <v-card>
        <v-card-title class="headline">{{ parentData }}</v-card-title>
        <v-card-text v-if="withObservations">
          <v-text-field
            v-model="observations"
            label="Observaciones (opcional)"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="confirm">Aceptar</v-btn>
          <v-btn color="green darken-1" text @click="dismiss">Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>

export default {
  name: 'DialogConfirm',
  props: {
    parentData: {
      type: String,
      default() {
        return "Confirma realizar la acción?";
      }
    },
    withObservations: {
      type: Boolean,
      default: false
    },
    showDialog: false
  },

  data() {
    return {
      observations: '',
    }
  },

  emits: ['on-close-dialog', 'confirm-action'],
  methods: {
    dismiss() {
      this.$emit("on-close-dialog");
    },

    confirm() {
      this.$emit("confirm-action", this.observations);
    }
  }
};
</script>

<template>
  <v-col md="4" class="mt-5">
    <span class="font-weight-medium">{{ fromFormatted }} al {{ toFormatted }}</span>
    <v-menu
      ref="menu"
      v-model="calendar"
      :close-on-content-click="false"
      :return-value.sync="dates"
      transition="scale-transition"
      offset-y
      min-width="auto"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          v-bind="attrs"
          v-on="on"
          color="secondary darken-1" class="ml-3"
          @click="resetDateSelector"
        >
          mdi-calendar
        </v-icon>
      </template>
      <v-date-picker
        v-model="dates"
        no-title scrollable range
      >
        <v-spacer></v-spacer>
        <v-btn
          text
          color="primary"
          @click="calendar = false"
        >
          Cancel
        </v-btn>
        <v-btn
          text color="primary"
          :disabled="dates.length === 0"
          @click="setCustomDate"
        >
          OK
        </v-btn>
      </v-date-picker>
    </v-menu>

  </v-col>
</template>

<script >
import moment from "moment/moment";
import FormDate from "@/components/base/FormDate.vue";

export default{
  name: "SearcherByPeriod",
  components: {FormDate},
  props: {
    from: {
      type: String,
      required: true
    },
    to: {
      type: String,
      required: true
    },
    machineId: {
      type: String,
    },
    endpoint: {
      type: String,
      required: true
    },
    reportEndpoint: {
      type: String
    },
  },
  emits: ['set-custom-date', 'reset-list'],
  data() {
    return {
      dates: [],
      calendar: false,
    }
  },
  computed: {
    fromFormatted() {
      return moment(this.from).format('D/M/Y')
    },
    toFormatted() {
      return moment(this.to).format('D/M/Y')
    },
  },
  async created() {
    await this.getResourcesAction()
  },
  methods: {
    async setCustomDate() {
      this.$refs.menu.save(this.dates)
      await this.$emit('set-custom-date', this.dates)
      await this.getResourcesAction()
    },
    async getResourcesAction() {
      await this.$store.dispatch(this.endpoint, {
        from: this.from,
        to: this.to,
        machineId: this.machineId
      });

      this.$emit('reset-list')
    },
    resetDateSelector() {
      this.dates = []
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <v-container fluid>
    <v-card>
      <v-container fluid>
        <v-row class="justify-center">
          <h1>Planificación Mantenimiento Edilicio</h1>
        </v-row>
        <v-row class="justify-center mt-7">
          <p>Este proceso realiza una programación de los conceptos de mantenimiento
            generando órdenes de trabajo para cada uno de ellos en función del período
            programado y de la frecuencia de cada concepto.
          </p>
          <p>
            Serán borradas todas las órdenes que ya se encuentran planificadas en el mismo período.
          </p>
        </v-row>
        <v-form>
          <v-row class="justify-center">
            <v-col md="2">
              <FormDate :label="labelFrom" :date="editedItem.from" @on-change="onChangeDateFrom"></FormDate>
            </v-col>
            <v-col md="2">
              <FormDate :label="labelTo" :date="editedItem.to" @on-change="onChangeDateTo"></FormDate>
            </v-col>
          </v-row>
          <v-row class="justify-center my-3">
            <v-btn
              color="primary"
              @click="showConfirmSchedule = true"
            >
              Programar
            </v-btn>
          </v-row>
        </v-form>
      </v-container>
    </v-card>

    <v-row class="justify-center mt-6">
      <v-btn @click="refreshScheduling">
        <v-icon class="mr-1">
          mdi-refresh
        </v-icon>
        Actualizar Planificacion
      </v-btn>
    </v-row>
    <v-row class="mt-6">
      <v-col md="6">
        <v-text-field
          filled solo rounded single-line hide-details
          v-model="searchSector"
          append-icon="mdi-magnify"
          label="Buscar Sector"
        />
        <v-data-table
          v-model="selectedSectors"
          show-select
          @toggle-select-all="selectOrDeselectSectors"
          @item-selected="selectSector"
          :headers="sectorHeaders"
          :items="getApplicableSectors"
          item-key="id"
          class="elevation-2 mt-5"
          :page.sync="sectorsPage"
          :items-per-page="5"
          :footer-props="{
            'items-per-page-options': [5, 10, 20, 30]
          }"
        >
          <template v-slot:item.schedule="{item}">
            <v-icon :color="item.seeing ? 'red darken-3' : 'primary'" @click="filterPlanningBySector(item)">
              {{ item.seeing ? 'mdi-eye-off' : 'mdi-eye' }}
            </v-icon>
          </template>
        </v-data-table>
      </v-col>

      <v-col md="6" v-if="seeingSectorPlannings">
        <v-text-field
          filled solo rounded :clearable="!seeingSectorPlannings"
          v-model="searchPlanning"
          single-line hide-details
          :readonly="seeingSectorPlannings"
        />

        <v-data-table
          show-select
          v-model="selectedPlannings"
          :headers="planningHeaders"
          :items="plannings"
          :search="searchPlanning"
          item-key="id"
          class="elevation-2 mt-5"
          :items-per-page="-1"
          hide-default-footer
        />
      </v-col>
    </v-row>

    <DialogConfirm
      :show-dialog="showConfirmSchedule" parent-data="¿Confirma la programación de órdenes?"
      @on-close-dialog="showConfirmSchedule = false"
      @confirm-action="saveSchedule"
    />
  </v-container>
</template>

<script>
import FormDate from "@/components/base/FormDate.vue";
import httpStatus from "http-status";
import moment from "moment";
import {mapGetters} from "vuex";
import DialogConfirm from "@/components/base/DialogConfirm.vue";

export default {
  name: "BuildingScheduleMaintenance",
  components: {DialogConfirm, FormDate},
  data: () => {
    return {
      showConfirmSchedule: false,
      seeingSectorPlannings: false,
      searchPlanning: '',
      searchSector: '',
      selectedPlannings: [],
      selectedSectors: [],
      plannings: [],
      sectorsPage: 1,
      planningHeaders: [
        {text: 'Descripción', value: 'concept.title'},
        {text: 'Frec. (dias)', value: 'daysFrequency'},
        {text: 'Día Inicio', value: 'startDay'},
        {text: 'Descripcion Sector', value: 'sectorDescription', align: ' d-none'},
      ],
      sectorHeaders: [
        {text: 'Descripción', value: 'description'},
        {text: 'Planif.', value: 'schedule'},
      ],
      labelFrom: 'Inicio',
      labelTo: 'Finalización',
      editedItem: '',
      defaultItem: {
        from: moment().format('YYYY-MM-DD'),
        to: moment().add(365,'days').format('YYYY-MM-DD')
      },
      required: [
        v => !!v || 'Este campo es obligatorio',
      ],
    }
  },

  computed: {
    ...mapGetters({
      getSectors: 'sectors/getSectors',
    }),
    getApplicableSectors() {
      return this.getSectors.filter(sector => sector.plannings?.length > 0 &&
          sector.description.toLowerCase().includes(this.searchSector.toLowerCase())
      )
    },
  },

  async created() {
    await this.$store.dispatch('sectors/getSectors');
    this.editedItem = Object.assign({}, this.defaultItem);
    this.clearSelection()
  },

  methods: {
    onChangeDateFrom(date) {
      this.editedItem.from = date
    },
    onChangeDateTo(date) {
      this.editedItem.to = date
    },

    async refreshScheduling() {
      await this.$store.dispatch('sectors/getSectors')
      this.$forceUpdate()
      this.cleanScheduling()
    },

    clearSelection() {
      const item = {
        value: false
      }
      this.selectOrDeselectSectors(item)
    },
    selectSector(selected) {
      if (selected.value) {
        this.addPlannings(selected.item)
      }else{
        selected.item.plannings.forEach((planning)=>{
          this.removePlaning(planning)
        })
      }
      this.selectedPlannings = this.plannings
    },
    // all selected plannings must be cleaned when deselect all sectors
    selectOrDeselectSectors(item) {
      if (item.value) {
        this.selectedSectors = this.getApplicableSectors
        this.plannings = []
        this.getApplicableSectors.forEach((sector) => {
          this.addPlannings(sector)
        });
        this.selectedPlannings = this.plannings
      } else {
        this.selectedSectors = []
        this.getApplicableSectors.forEach((sector) => {
          sector.plannings.forEach((planning) => {
            this.removePlaning(planning)
          });
        });
        this.selectedPlannings = []
      }
    },

    addPlannings(sector) {
      sector.plannings.forEach(planning => {
        planning.sectorDescription = 'Sector: ' + sector.description
      })
      this.plannings.push(...sector.plannings)
    },
    removePlaning(planning) {
      const index = this.plannings.indexOf(planning)
      if (index > -1)
        this.plannings.splice(this.plannings.indexOf(planning), 1)
    },

    filterPlanningBySector(sector) {
      if (sector.seeing) {
        sector.seeing = false
        this.searchPlanning = ''
        this.seeingSectorPlannings = false

      } else {
        this.getApplicableSectors.forEach(sector => {
          sector.seeing = false
        })
        sector.seeing = true
        this.searchPlanning = 'Sector: ' + sector.description
        this.seeingSectorPlannings = true
      }

      this.$forceUpdate()
    },

    async saveSchedule() {
      this.showConfirmSchedule = false

      const response = await this.$store.dispatch('buildingOrders/saveBuildingSchedule', {
        plannings: this.selectedPlannings,
        from: this.editedItem.from,
        to: this.editedItem.to,
      });

      if (response.status === httpStatus.OK) {
        this.cleanScheduling()
      }
    },

    cleanScheduling() {
      this.editedItem = Object.assign({}, this.defaultItem);

      this.searchSector = ''
      this.searchPlanning = ''
      this.seeingSectorPlannings = false
      this.plannings = []
      this.getApplicableSectors.forEach(sector => {
        sector.seeing = false
      })

      this.clearSelection()
      this.$nextTick(() => {
        this.sectorsPage = 1
      })
    },
  }

}
</script>

<style scoped>

</style>
<template>
  <div>
    {{ getMessage }}
  </div>
</template>

<script>
import {mapGetters} from 'vuex';

export default {
  data() {
    return {}
  },
  computed: {
    ...mapGetters({
      getAlertShow: 'app/getAlertShow',
      getAlertMessage: 'app/getAlertMessage',
      getAlertType: 'app/getAlertType'
    }),
    getMessage: function () {
      if (this.getAlertShow) {
        this.$toasted.show(this.getAlertMessage, {
          action: {
            icon: 'close',
            onClick: (e, toastObject) => {
              toastObject.goAway(0)
              this.$store.commit("app/setAlertNotificaton", {show: false})
            },
          },
          type: this.getAlertType,
          theme: 'bubble',
          iconPack: "mdi"
        });
      }
    }
  }
}
</script>
<template>

  <div>

    <v-tabs v-model="tab">

      <v-tab key="Características">Características</v-tab>
      <v-tab key="Máquinas" v-if="editedIndex !== -1">Máquinas</v-tab>

    </v-tabs>

    <v-tabs-items v-model="tab">

      <v-tab-item key="Características">

        <v-form ref="partForm">
          <v-row>
            <v-col md="3">
              <v-text-field
                label="Código"
                v-model="editedItem.code.value"
                :rules="required"
                @input="() => editedItem.code.value = editedItem.code.value.toUpperCase()"
              />
            </v-col>
            <v-col md="6">
              <v-text-field
                label="Descripción"
                v-model="editedItem.description"
                :rules="required"
              />
            </v-col>
            <v-col md="2">
              <v-checkbox
                v-model="editedItem.critical"
                label="Repuesto crítico"
              />
            </v-col>

            <v-col md="4">
              <v-autocomplete
                v-model="editedItem.supplier"
                label="Proveedor"
                :items="getSuppliers"
                item-text="rsocial"
                item-value="rsocial"
              />
            </v-col>

            <v-col md="3">
              <Money :money="editedItem.cost" :label="costLabel"></Money>
            </v-col>
            <v-col md="5">
              <FormDate :label="label" :date="editedItem.costDate" @on-change="onChangeDate"></FormDate>
            </v-col>

            <v-col md="12">
              <v-textarea label="Observaciones" v-model="editedItem.observations"></v-textarea>
            </v-col>
          </v-row>

        </v-form>
      </v-tab-item>

      <v-tab-item key="Máquinas">

        <v-col md="6">
          <v-form ref="machineForm">
            <v-autocomplete
              ref="partMachine"
              label="Máquina"
              v-model="machine"
              :items="getMachines"
              item-text="description"
              :rules="required"
              return-object
            >
              <template v-slot:selection="data">
                {{ data.item.description + " | " + data.item.code.value }}
              </template>

              <template v-slot:item="data">
                <v-list-item-content
                  v-text="data.item.description + ' | ' + data.item.code.value"></v-list-item-content>
              </template>

            </v-autocomplete>
          </v-form>
        </v-col>

        <v-data-table
          :headers="headers"
          :items="editedItem.machines"
          :items-per-page="-1"
          item-key="id"
          class="elevation-2"
        >
          <template v-slot:item.delete="{item}" class="elevation-1">
            <v-icon @click="removeMachine(item)">
              mdi-delete
            </v-icon>
          </template>

        </v-data-table>
      </v-tab-item>
    </v-tabs-items>

    <v-btn @click="save" outlined>{{ getAction }}</v-btn>

  </div>


</template>

<script>

import httpStatus from "http-status";
import {v4} from "uuid";
import Money from "@/components/Money.vue";
import FormDate from "@/components/base/FormDate.vue";
import {mapGetters} from "vuex";

export default {
  components: {Money, FormDate},
  computed: {
    ...mapGetters({
      getMachines: "machines/getMachines",
      getSuppliers: "app/getSuppliers"
    }),
    getAction() {
      if (this.tab === 0) return 'Guardar'
      if (this.tab === 1) return 'Agregar Máquina'
    }
  },

  name: "MachineForm",
  data() {
    return {
      machine: "",
      headers: [
        {text: 'Código', value: 'code.value'},
        {text: 'Descripción', value: 'description'},
        {text: 'Marca', value: 'brand'},
        {text: 'Modelo', value: 'model'},
        {text: 'Borrar', value: 'delete'},
      ],
      tab: null,
      costLabel: "Costo",
      label: "Fecha de costo",
      required: [
        v => !!v || 'Este campo es obligatorio',
      ],
      criticality: [
        {name: 'A', value: 'A'},
        {name: 'B', value: 'B'},
        {name: 'C', value: 'C'},
      ]
    }
  },

  props: ['editedItem', 'editedIndex'],

  async created() {
    await this.$store.dispatch("app/getSuppliers")
    await this.$store.dispatch("machines/getMachines")
  },

  methods: {

    onChangeDate(value) {
      this.editedItem.costDate = value
    },

    async removeMachine(item) {
      await this.$store.dispatch('parts/removeMachine', {part: this.editedItem, machine: item})
    },


    async save() {

      let newPart = Object.assign({}, this.editedItem);

      if (this.tab === 0) {
        if (!this.$refs.partForm.validate()) return;

        const response = await this.$store.dispatch('parts/createPart', {
          item: newPart,
          editedIndex: this.editedIndex
        });

        if (response.status === httpStatus.CREATED) {
          this.onCloseDialog()
        }
      } else {

        if (this.editedIndex === -1) {
          this.$store.commit('app/setSnackMsg', 'Primero debe guardar el repuesto para asignar máquinas');
          this.$store.commit('app/showSnack');
          return;
        }

        if (!this.$refs.machineForm.validate()) return
        await this.$store.dispatch('parts/addMachine', {
          editedIndex: this.editedIndex,
          machine: this.machine,
          part: newPart
        });
        this.$refs.partMachine.reset()
      }
    },

    onCloseDialog() {
      this.$emit('on-close-dialog');
    }
  }
}
</script>

<style scoped>

</style>
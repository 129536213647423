<template>
  <v-container fluid>
    <v-btn
      @click="newOrder"
      color="primary" outlined
      class=""
    >
      Nueva
    </v-btn>

    <v-card class="mt-3">
      <v-container>
        <v-row>
          <v-col md="2">
            <v-card-title>
              Órdenes
              <v-icon class="ml-3" color="primary" @click="() => {
                searcherKey ++
              }">
                mdi-history
              </v-icon>
              <v-icon
                v-if="selectedOrders.length > 0"
                color="success darken-1" class="ml-3"
                @click="getSelectedOrdersReport"
              >
                mdi-printer
              </v-icon>
              <v-icon
                v-if="selectedOrders.length > 0"
                class="ma-2" color="red darken-3" @click="showDialogDelete = true"
              >
                mdi-delete
              </v-icon>
            </v-card-title>
          </v-col>

          <v-col md="5">
            <v-form ref="selectMachine" class="mt-1">
              <v-autocomplete
                filled rounded dense clearable
                ref="machine"
                :label="getMachineText"
                v-model="selectedMachine"
                :items="getMachines"
                item-text="code.value"
                item-value="id"
              >
                <template v-slot:selection="data">
                  {{ data.item.description + " | " + data.item.code.value }}
                </template>

                <template v-slot:item="data">
                  <v-list-item-content
                    v-text="data.item.description + ' | ' + data.item.code.value"></v-list-item-content>
                </template>
              </v-autocomplete>
            </v-form>
          </v-col>

          <v-col md="1" class="mt-3">
            <v-menu
              :close-on-content-click="false"
              bottom
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-btn
                  rounded
                  color="secondary"
                  dark
                  v-on="on"
                  prepend-icon="mdi-calendar"
                >
                  <v-icon
                    dark
                  >
                    mdi-filter-variant
                  </v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-container fluid>
                  <v-row>
                    <v-col md="6">
                      <v-card-subtitle>
                        Estado
                      </v-card-subtitle>
                      <v-divider></v-divider>
                      <v-radio-group v-model="orderState" mandatory>
                        <v-radio label="Todas"/>
                        <v-radio :value="FINISHED().value" label="Finalizada"/>
                        <v-radio :value="PENDING().value" label="Pendiente"/>
                        <v-radio :value="EXPIRED().value" label="Expirada"/>
                        <v-radio :value="CANCELLED().value" label="Cancelada"/>
                      </v-radio-group>
                    </v-col>
                    <v-col md="6">
                      <v-card-subtitle>
                        Tipo
                      </v-card-subtitle>
                      <v-divider></v-divider>
                      <v-radio-group v-model="orderType" mandatory>
                        <v-radio label="Todas"/>
                        <v-radio :value="PREVENTIVE_ORDER()" label="Preventiva"/>
                        <v-radio :value="CORRECTIVE_ORDER()" label="Correctiva"/>
                      </v-radio-group>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card>
            </v-menu>
          </v-col>

          <SearcherByPeriod
            :key="searcherKey"
            :to="to" :from="from"
            endpoint="orders/getOrders"
            report-endpoint="orders/getOrdersByPeriodReport"
            @set-custom-date="setCustomDate"
            @reset-list="resetList"
          />

        </v-row>
      </v-container>

      <v-data-table
        show-select
        @toggle-select-all="selectOrDeselectAllOrders"
        :headers="headers"
        :items="getFilteredOrders"
        item-key="id"
        class="elevation-2"
        :items-per-page="15"
        :page.sync="page"
        :footer-props="{
          'items-per-page-options': [15, 25, 40]
        }"
      >
        <template v-slot:body="{items}" class="elevation-1">
          <tr class="hoverable" :class="{'marked': item.orderState.value === 'finished'}" v-for="item in items" :key="item.id">
            <td>
              <v-checkbox v-model="selectedOrders" :value="item" style="margin-left:15px; margin-top: 0; padding:0" hide-details />
            </td>
            <div class="ml-4 mt-1">
              <td>{{ item.number.value }}</td>
            </div>
            <td>{{ getScheduleDate(item) }}</td>
            <td
              class="font-weight-bold"
              :class="{'blue--text': item.type === PREVENTIVE_ORDER(), 'red--text': item.type === CORRECTIVE_ORDER()}"
            >
              {{ getType(item) }}
            </td>
            <td>
              <v-chip
                small
                :color="getOrderState(item.orderState.value).color"
                text-color="white"
              >
                <v-avatar left>
                  <v-icon small>{{ getOrderState(item.orderState.value).icon }}</v-icon>
                </v-avatar>
                {{ getOrderState(item.orderState.value).text }}
              </v-chip>
            </td>
            <td>{{ getMachine(item) }}</td>
            <td>{{ getConcept(item) }}</td>

            <td>
              <v-icon small class="ma-2" @click="showReport(item.id)">
                mdi-eye
              </v-icon>
              <v-icon v-if="item.orderState.value !== FINISHED().value" small class="ma-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
              <v-icon small class="ma-2" @click="openCloseOrder(item)">
                mdi-folder-check
              </v-icon>
            </td>
          </tr>
        </template>

      </v-data-table>
    </v-card>

    <BaseForm :showDialog="showDialog" :cardTitle="getCardTitle" @on-close-dialog="closeDialog">
      <OrderForm :editedItem="editedItem" :editedIndex="editedIndex" @on-save="onSave"></OrderForm>
    </BaseForm>

    <BaseForm :showDialog="showDialogCloseOrder" :cardTitle="getCloseOrderTitle" @on-close-dialog="closeDialogCloseOrder">
      <CloseOrderForm :editedItem="editedItem" :editedIndex="editedIndex" @on-save="closeDialogCloseOrder"/>
    </BaseForm>

    <DialogDetailDelete :showDialogDelete="showDialogDelete" @on-close-delete-item="onCloseDeleteItem"
                        @delete-item="confirmDelete"></DialogDetailDelete>
  </v-container>
</template>

<script>

import OrderForm from "@/components/machines/OrderForm.vue";
import BaseForm from "@/components/base/BaseForm.vue";
import {mapGetters} from "vuex";
import {v4} from "uuid";
import DialogDetailDelete from "@/components/base/DialogDetailDelete.vue";
import CloseOrderForm from "@/components/machines/CloseOrderForm.vue";
import RefreshButton from "@/components/base/RefreshButton.vue";
import {CANCELLED, EXPIRED, FINISHED, getOrderState, PENDING} from "@/types/OrderStates";
import {CORRECTIVE_ORDER, PREVENTIVE_ORDER} from "@/types/OrderTypes";
import moment from "moment";
import SearcherByPeriod from "@/components/base/SearcherByPeriod.vue";

export default {
  name: "Orders",

  components: {
    SearcherByPeriod,
    RefreshButton,
    OrderForm,
    BaseForm,
    DialogDetailDelete,
    CloseOrderForm
  },

  data() {
    return {
      showDialogCloseOrder: false,
      cardTitleCloseOrder: 'Cierre de órden de mantenimiento',
      deleteReason: '',
      showDialogDelete: false,
      showDialog: false,
      searcherKey: 0,
      page: 1,
      from: moment().subtract(6, 'days').format('YYYY-MM-DD'),
      to: moment().format('YYYY-MM-DD'),
      orderState: '',
      orderType: '',
      headers: [
        {text: 'Num.', value: 'number.value'},
        {text: 'Programada', value: 'scheduleDate'},
        {text: 'Tipo', value: 'type', width: 100},
        {text: 'Estado', value: 'orderState.value', width: 150},
        {text: 'Máquina', value: 'machine'},
        {text: 'Concepto', value: 'concept'},
        {text: 'Acciones', value: 'actions'},
      ],
      selectedMachine: null,
      selectedOrders: [],
      editedItem: '',
      editedIndex: -1,
      defaultItem: () => {
        return {
          id: v4(),
          type: '',
          machine: '',
          machineObject: {},
          completedBy: "",
          files: [],
          file: {
            id: v4(),
            name: {
              value: ''
            }
          },
          orderState: {
            value: PENDING.value
          },
          issuer: "",
          planning: "",
          scheduleDate: '',
          number: {
            value: ''
          },
          observations: "",
          description: "",
          transferredFiles: []
        }
      }
    }
  },

  computed: {
    ...mapGetters({
      getOrders: 'orders/getOrders',
      getUsername: 'app/getUserName',
      getMachines: 'machines/getMachines',
    }),
    getMachineText() {
      return this.selectedMachine
        ? 'Máquina seleccionada'
        : 'Todas las Máquinas'
    },
    getCardTitle() {
      return this.editedIndex > -1
        ? 'Editar Orden de Mantenimiento N°' + this.editedItem.number?.value.toString()
        : 'Nueva Órden de Mantenimiento'
    },
    getCloseOrderTitle() {
      return 'Cierre de Orden N° ' + this.editedItem.number?.value.toString()
    },
    getFilteredOrders() {
      let orders = this.getOrders.filter(order => order.machine?.id || order.planning?.machine?.id)

      if (this.selectedMachine) {
        orders = orders.filter(order => {
          if (order.machine?.id) {
            return order.machine.id === this.selectedMachine
          }
          if (order.planning?.machine) {
            return order.planning.machine.id === this.selectedMachine
          }
          return false
        })
      }

      const typeFilteredOrders = (status) => orders.filter(order => order.type === status)
      const stateFilteredOrders = (status) => orders.filter(order => order.orderState.value === status)

      switch (this.orderType) {
        case CORRECTIVE_ORDER:
          orders = typeFilteredOrders(CORRECTIVE_ORDER)
          break
        case PREVENTIVE_ORDER:
          orders = typeFilteredOrders(PREVENTIVE_ORDER)
          break
      }
      switch (this.orderState) {
        case FINISHED.value:
          orders = stateFilteredOrders(FINISHED.value)
          break
        case PENDING.value:
          orders = stateFilteredOrders(PENDING.value)
          break
        case EXPIRED.value:
          orders = stateFilteredOrders(EXPIRED.value)
          break
        case CANCELLED.value:
          orders = stateFilteredOrders(CANCELLED.value)
          break
      }

      return orders
    },

    getScheduleDate() {
      return item => moment(item.scheduleDate).format('DD/MM/YYYY')
    },
    getMachine() {
      return item => {
        if (item.machine?.description)
          return item.machine.code.value + ' - ' + item.machine.description
        if (item.planning?.machine)
          return item.planning.machine.code.value + ' - ' + item.planning.machine.description
        return ''
      }
    },
    getConcept() {
      return item => item.planning && item.planning.concept
        ? item.planning.concept.title ?? ''
        : ''
    },
    getType() {
      return item => {
        if (item.type === PREVENTIVE_ORDER) {
          return 'P';
        }
        if (item.type === CORRECTIVE_ORDER) {
          return 'C';
        }
      }
    },
  },

  async created() {
    this.editedItem = Object.assign({}, this.defaultItem)
    this.editedItem.issuer = this.getUsername
    await this.$store.dispatch('app/getEmployees');
    await this.$store.dispatch('machines/getMachines');
  },

  methods: {
    getOrderState,
    CORRECTIVE_ORDER() {
      return CORRECTIVE_ORDER
    },
    PREVENTIVE_ORDER() {
      return PREVENTIVE_ORDER
    },
    CANCELLED() {
      return CANCELLED
    },
    EXPIRED() {
      return EXPIRED
    },
    PENDING() {
      return PENDING
    },
    FINISHED() {
      return FINISHED
    },
    setCustomDate(dates) {
      if (dates.length === 1)
        this.from = this.to = moment(dates[0]).format('YYYY-MM-DD')
      if (dates.length === 2) {
        this.from = dates[0]
        this.to = dates[1]
      }
      this.checkPeriod()
    },
    checkPeriod() {
      const from = new Date(this.from)
      const to = new Date(this.to)
      if (from.getTime() > to.getTime()) {
        [this.from, this.to] = [this.to, this.from]
      }
    },
    resetList() {
      this.$nextTick(() => {
        this.page = 1
      })
      this.selectedOrders = []
    },

    selectOrDeselectAllOrders(checked) {
      this.selectedOrders = checked.value
        ? [...this.getFilteredOrders]
        : []
    },

    async showReport(id) {
      await this.$store.dispatch('orders/getReportOrder', id);
    },
    async getSelectedOrdersReport() {
      const orderIds = this.selectedOrders.map(order => order.id)
      await this.$store.dispatch('orders/getFilteredOrdersReport', orderIds);
    },

    async confirmDelete(deleteReason) {
      const response = await this.$store.dispatch('orders/deleteOrder', {
        orderIds: this.selectedOrders.map(order => order.id),
        deleteReason: deleteReason
      });

      if (response.status === 200) {
        this.showDialogDelete = false
        this.selectedOrders = []
      }
    },
    onCloseDeleteItem() {
      this.showDialogDelete = false;
    },

    newOrder() {
      this.showDialog = true;
      this.editedItem = this.defaultItem()
      this.editedIndex = -1;
    },

    async closeDialogCloseOrder() {
      this.showDialogCloseOrder = false;
      this.searcherKey ++
      this.resetList()
    },
    async openCloseOrder(item) {
      this.editedItem = await this.$store.dispatch('orders/getOrder', item.id)
      this.editedItem.transferredFiles = []
      this.editedItem.machineObject = {}
      this.editedItem.completedByArray = this.editedItem.completedBy
        ? this.editedItem.completedBy.split(', ')
        : []

      if (!this.editedItem.startDateTime) {
        this.editedItem.startDateTime = moment(new Date()).format('YYYY-MM-DDTkk:mm')
        this.editedItem.endDateTime = moment(new Date()).format('YYYY-MM-DDTkk:mm')
      } else {
        this.editedItem.startDateTime = moment(this.editedItem.startDateTime).add(3, 'hours').format('YYYY-MM-DDTkk:mm')
        this.editedItem.endDateTime = moment(this.editedItem.endDateTime).add(3, 'hours').format('YYYY-MM-DDTkk:mm')
      }

      this.editedItem.file = {
        id: v4(),
        name: {
          value: ''
        }
      }
      this.editedIndex = this.getOrders.indexOf(item)
      this.showDialogCloseOrder = true;
    },

    async editItem(item) {
      this.editedItem = Object.assign({}, item)
      this.editedItem.machineObject = {}
      this.editedItem.transferredFiles = []

      const machineId = item.machine?.id ?? item.planning.machine.id
      const machineObject = await this.$store.dispatch('machines/getMachine', machineId)
      this.editedItem.machineObject.plannings = machineObject.plannings.filter(planning => !planning.isAutonomous)
      this.editedItem.machine = machineId

      this.editedItem.planning = this.editedItem.planning?.id ?? ''

      this.editedIndex = this.getOrders.indexOf(item)
      this.showDialog = true;
    },

    onSave() {
      this.closeDialog()
      this.searcherKey ++
      this.resetList()
    },
    closeDialog() {
      this.showDialog = false
    },

  }
}</script>

<style scoped>
.marked {
  background-color: #97c897;
}
.hoverable:hover {
  background: rgb(223, 222, 222);
}
</style>
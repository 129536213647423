
import Vue from 'vue';
import Navigation from '@/components/base/Navigation.vue';
import {mapGetters} from "vuex";
import axios from "axios";
import Snackbar from '@/components/base/Snackbar.vue';
import AlertNotification from "@/components/base/AlertNotification.vue"
import httpStatus from "http-status";
import LoadingIndicator from "@/components/base/LoadingIndicator.vue";

export default Vue.extend({
  name: 'App',

  components: {
    LoadingIndicator,
    Navigation,
    Snackbar,
    AlertNotification
  },

  data: () => ({
    //
  }),

  computed: {
    ...mapGetters({
      isAuthenticated: 'app/isAuthenticated'
    })
  },

  created() {
    let me = this;
    let method: string|undefined = ''

    axios.interceptors.request.use((request) => {
      me.$store.commit('app/requestStart');
      method = request.method
      return request;
    });

    axios.interceptors.response.use(
        function (resp) {
          me.$store.commit('app/requestStop');
          if (method && method !== 'get') {
            me.$store.commit('app/showSnack');
            me.$store.commit('app/setSnackColor', 'primary');
            me.$store.commit('app/setSnackMsg', 'Proceso exitoso');
          }

          return resp;
        },
        async (error) => {
          me.$store.commit('app/requestStop');
          me.$store.commit("app/setAlertNotificaton", {show: true, msg: `Error ${error.response.status}: ${error.response.data}`})

          if (error.response.status === httpStatus.UNAUTHORIZED) {
            console.trace();
            await this.$store.dispatch('app/logout')
            await this.$router.push("login")
          }

          return Promise.reject(error);
        }
    );
  }

});

<template>
  <v-btn @click="refresh()" class="">
    <v-icon class="mr-2">mdi-refresh</v-icon>
    {{ title }}
  </v-btn>
</template>

<script>
export default {
  name: 'RefreshButton',
  props: {
    title: {
      type: String,
      default: 'Actualizar'
    },
    module: {
      type: String,
      required: true,
    },
    table: {
      type: String,
      required: true,
    },
  },
  methods: {
    refresh() {
      this.$store.dispatch('' + this.module + '/get' + this.table.charAt(0).toUpperCase() + this.table.substring(1))
    }
  }
}
</script>

<style>

</style>